import React, {Component} from 'react';
// import {useForm} from "react-hook-form";
import {Growl} from 'primereact/growl';
import {PropostaService} from '../service/PropostaService';
import {InputText} from 'primereact/inputtext';
import {InputMask} from "primereact/inputmask";
import {InputSwitch} from 'primereact/inputswitch';
// import {InputTextarea} from 'primereact/inputtextarea';
import { Dropdown} from 'primereact/dropdown';
import { Button } from 'primereact/button';
import {Card} from 'primereact/card';
// import {FileUpload} from 'primereact/fileupload';
import {Dialog} from 'primereact/dialog';
import Guid from './Guid';
import firebase from '../Firebase';
// import {Mailgun} from 'mailgun-js';
// import {Messages} from 'primereact/messages';
// import {Message} from 'primereact/message';
import './PropostaPage.css';
import moment from "moment";
import 'moment/locale/pt-br';
import FileUploader from "react-firebase-file-uploader";
import {ProgressBar} from 'primereact/progressbar';
import ReactHtmlParser from 'react-html-parser'; 
// import ReactToPrint from 'react-to-print';
import Contrato from './Contrato';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';



export class PropostaPage extends Component {

    constructor(props) {
        super(props);
        //console.log('props:',this.props.match.params.id);

        this.foto = '/assets/layout/images/foto.svg';

        var parametroPasssado = this.props.match.params.id;
        if (parametroPasssado !== undefined){
            if (parametroPasssado.length === 9){
                this.propostaId = undefined;
                this.cpfCorretor = parametroPasssado;
            } else {
                this.propostaId = parametroPasssado;
                this.cpfCorretor = null;
            }
        } else {
            this.propostaId = undefined;
            this.cpfCorretor = null;
        }
         
        //console.log('this.propostaId:',this.propostaId);
        this.propostaService = new PropostaService();
        firebase.auth().signInAnonymously().catch(function(error) {
            // Handle Errors here.
            this.errorCode = error.code;
            this.errorMessage = error.message;
            // ...
        });

        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
              //var isAnonymous = user.isAnonymous;
              //var uid = user.uid;
              try {
                this.user = user;
              } catch (error) {
                console.log('error:', error)
              }
              
              //console.log('user', user)
            } else {
              // User is signed out.
              // ...
            }
            // ...
        })


        if (this.propostaId === undefined) {
            //console.log('carregando zerado');
            this.state = this.getStateZerado(this.cpfCorretor);
        } 
        else
        {
            //console.log('carregando ', this.propostaId);
            const pathjson = 'propostas/' + this.propostaId;
            const ref = firebase.database().ref(pathjson);
            ref.on('value', snapshot => {
                //console.log('inicio::snapshot -> state.fields:', snapshot.val())  
                if (snapshot.val()) {
                    this.setState({fields: snapshot.val()});
                    //console.log('inicio::snapshot key:', snapshot.val().key);
                }
                //console.log('inicio::state:', this.state);
            });
        }

        this.setState({'exibirContrato': false});
        this.setState({'exibirInfoCategorias': false});
        this.setState({'exibirInfoClubeDescontos': false});
        this.setState({'exibirInfoClubeBeneficios': false});

        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.atualizarValores = this.atualizarValores.bind(this);
        
        this.zeraState = this.zeraState.bind(this);
        // this.onPlanoChange = this.onPlanoChange.bind(this);
        // this.onCategoriaChange = this.onCategoriaChange.bind(this);
        // this.onCremacaoChange = this.onCremacaoChange.bind(this);
        //this.onClickEnviar = this.onClickEnviar.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.saveFormSemMensagens = this.saveFormSemMensagens.bind(this);
        this.incluirBeneficiario = this.incluirBeneficiario.bind(this);
        this.exibirMensagemExclusaoDependente = this.exibirMensagemExclusaoDependente.bind(this)
        this.exibirMensagemExclusaoArquivo = this.exibirMensagemExclusaoArquivo.bind(this)
        this.cancelarRemocao = this.cancelarRemocao.bind(this);
        this.cancelarRemocaoArquivo = this.cancelarRemocaoArquivo.bind(this);
        this.removerBeneficiario = this.removerBeneficiario.bind(this);
        this.removerArquivo = this.removerArquivo.bind(this);
        this.uploaderHandler = this.uploaderHandler.bind(this);
        this.onUploadHandler = this.onUploadHandler.bind(this);
        this.validaBeneficiario = this.validaBeneficiario.bind(this);
        this.getErroBeneficiario = this.getErroBeneficiario.bind(this);
        this.exibirContrato = this.exibirContrato.bind(this);
        this.esconderContrato = this.esconderContrato.bind(this);
        this.exibirInfoCategorias = this.exibirInfoCategorias.bind(this);
        this.esconderInfoCategorias = this.esconderInfoCategorias.bind(this);
        this.exibirInfoClubeBeneficios = this.exibirInfoClubeBeneficios.bind(this);
        this.esconderInfoClubeBeneficios = this.esconderInfoClubeBeneficios.bind(this);
        this.exibirInfoClubeDescontos = this.exibirInfoClubeDescontos.bind(this);
        this.esconderInfoClubeDescontos = this.esconderInfoClubeDescontos.bind(this);

        //this.imprimeElemento = this.imprimeElemento.bind(this);
        
    }

    handleChangeUsername = event => this.setState({ username: event.target.value });
    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress, progress });
    handleUploadError = error => this.setState({ isUploading: false });
    handleUploadSuccess = filename => {
        let fields = this.state?.fields;

        this.setState({ progress: 100, isUploading: false });
        firebase
            .storage()
            .ref("users")
            .child(filename)
            .getDownloadURL()
            .then(url => {
                // var minhaRef = firebase.storage().ref("users").child(filename);
                // minhaRef.getMetadata().then(function(metadata) {
                //     // Metadata now contains the metadata for 'images/forest.jpg'
                //     console.log('meta:', metadata);
                //     console.log('arquivo:', fields.arquivo);
                //     console.log('fields:', fields);
                //     let arqnovo = {
                //         //id: fileId,
                //         fullpath: url,
                //         //webpath: url,
                //         bytes: metadata.size,
                //         name: filename.split('/').pop()
                //     };
                //     fields.arquivo = arqnovo;
                //     console.log('arqnovo:', arqnovo)
                //   });
                let arqnovo = {
                            //id: fileId,
                            fullpath: url,
                            //webpath: url,
                            //bytes: metadata.size,
                            name: filename.split('/').pop()
                };
                fields.arquivo = arqnovo;
                this.setState({ fields: fields });
            });
    };

    //REVISADO
    handleChange(e) {
        console.log('handleChange...' + e.target.name + ' - ' + e.target.value);
        let fields = this.state?.fields;
        if (e.target.value && e.target.value !== undefined && e.target.value !== null && e.target.value !== true && e.target.value !== false && e.target.name !== 'nascimento')
            e.target.value = e.target.value.toUpperCase();

        fields[e.target.name] = e.target.value;

        if (e.target.name === 'diaDePagamento') {

            let agora = new Date();
            let agoraMoment = moment(agora);
            let dataDeInicio = moment(new Date(agora.getFullYear(), agora.getMonth(), fields.diaDePagamento));
            fields.dataDeInicio = dataDeInicio.format('L');

            while ((dataDeInicio.diff(agoraMoment, 'days') + 1) < 15){
                dataDeInicio = dataDeInicio.add(1, 'months')
                fields.dataDeInicio = dataDeInicio.format('L');
            }
        }

        

        this.setState({
          fields
        });

        if (e.target.name === 'plano' && e.target.value === 'F'){
            this.incluirBeneficiario();
        }

        if (e.target.name === 'plano' && e.target.value === 'I'){
            fields.dependentes = [];
        }

        if ((e.target.name === 'nascimento' && this.obterData(e.target.value) != null) || e.target.name === 'clubeBeneficios' || e.target.name === 'clubeDescontos' || e.target.name === 'cremacao' || e.target.name === 'plano' || e.target.name === 'categoria') {
            this.atualizarValores(this.state.fields);
        }

        
    }

    obterValorFormatado(valor){
        return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL"}).format(valor)
    }

    validarCPF(cpf) {	
        cpf = cpf.replace(/[^\d]+/g,'');	
        if(cpf === '') return false;	
        // Elimina CPFs invalidos conhecidos	
        if (cpf.length !== 11 || 
            cpf === "00000000000" || 
            cpf === "11111111111" || 
            cpf === "22222222222" || 
            cpf === "33333333333" || 
            cpf === "44444444444" || 
            cpf === "55555555555" || 
            cpf === "66666666666" || 
            cpf === "77777777777" || 
            cpf === "88888888888" || 
            cpf === "99999999999")
                return false;		
        // Valida 1o digito	
        var add = 0;
        var rev = 0;	
        for (var i=0; i < 9; i ++)		
            add += parseInt(cpf.charAt(i)) * (10 - i);	
            rev = 11 - (add % 11);	
            if (rev === 10 || rev === 11)		
                rev = 0;	
            if (rev !== parseInt(cpf.charAt(9)))		
                return false;		
        // Valida 2o digito	
        add = 0;	
        for (let ii = 0; ii < 10; ii ++)		
            add += parseInt(cpf.charAt(ii)) * (11 - ii);	
        rev = 11 - (add % 11);	
        if (rev === 10 || rev === 11)	
            rev = 0;	
        if (rev !== parseInt(cpf.charAt(10)))
            return false;		
        return true;   
    }

    atualizarValores(proposta){
        if (proposta && this.obterData(proposta.nascimento) != null){
            proposta.dataAceite = Date.now();
            proposta.inscricao = 50.00;
    
            let agora = new Date()
            let agoraMoment = moment(agora);
            let dataDeInicio = moment(new Date(agora.getFullYear(), agora.getMonth(), proposta.diaDePagamento));
            let dataDeNascimento = moment(this.obterData(proposta.nascimento))
    
            //SETANDO DATA DE INICIO
            while ((dataDeInicio.diff(agoraMoment, 'days') + 1) < 20){
                dataDeInicio = dataDeInicio.add(1, 'months')
                proposta.dataDeInicio = dataDeInicio.format('L');
            }
    
            //SETANDO IDADE DO TITULAR NA DATA DE INICIO
            proposta.idade = dataDeInicio.diff(dataDeNascimento, 'years');
    
            //setando quantidade de vidas e tabela/valores nos dependentes
            proposta.quantidadeDeVidas = 1;
            proposta.mensalidade = 0;
            proposta.adicional = 0;
            proposta.valorTotalMensal = 0;
            proposta.valorTotalAdicional =0;
    
            if (proposta.plano === 'F'){
                //if (proposta.dependentes.length == 0){
                    //proposta.plano = 'I';
                //}
                proposta.quantidadeDeVidas = proposta.dependentes.length + 1 ;
                proposta.dependentes.forEach(dependente => {
                    if (this.obterData(dependente.dataDeNascimento) != null){
                        //console.log('obterdata:', this.obterData(dependente.dataDeNascimento))
                        let nascimento = moment(this.obterData(dependente.dataDeNascimento))
                        dependente.idade = dataDeInicio.diff(nascimento, 'years');
                        let tabela = this.propostaService.util.tabela.find(x => x.categoria === proposta.categoria &&  dependente.idade >= x.idadeinicial && dependente.idade <= x.idadefinal);
                        if (tabela && proposta.quantidadeDeVidas >= 3){
                            dependente.tabela = tabela.codigo + '.3V' + (proposta.cremacao ? '.CREMA': '');
                            dependente.valor = tabela.valor3vidasoumais;
                        } else if (tabela && proposta.quantidadeDeVidas >= 2) {
                            dependente.tabela = tabela.codigo + '.2V' + (proposta.cremacao ? '.CREMA': '');
                            dependente.valor = tabela.valor2vidas;
                        } else if (tabela){
                            dependente.tabela = tabela.codigo + '.1V' + (proposta.cremacao ? '.CREMA': '');
                            dependente.valor = tabela.valor1vida;
                        }
        
                        dependente.adicional = (dependente.valor * (proposta.cremacao ? tabela.fatorcremacao: 1)) - dependente.valor;

                        //CLUBE DE DESCONTOS
                        dependente.adicional += (dependente.clubeDescontos ? tabela.clubedescontos : 0);
                        //CLUBE DE BENEFICIOS
                        dependente.adicional += (dependente.clubeBeneficios ? tabela.clubebeneficios : 0);

                        proposta.valorTotalAdicional += dependente.adicional
                        proposta.valorTotalMensal += dependente.valor + dependente.adicional
                    } else {
                        dependente.valor = null;
                        dependente.idade = null;
                        dependente.adicional = null;
                    }
                });
            }

            // if (proposta.plano === 'I'){
            //     proposta.dependentes = [];
            // }
            
            //setando tabela e valor do titular
            let tabelavalores = this.propostaService.util.tabela.find(x => x.categoria === proposta.categoria &&  proposta.idade >= x.idadeinicial && proposta.idade <= x.idadefinal);
            if (tabelavalores && proposta.quantidadeDeVidas >= 3){
                proposta.tabela = tabelavalores.codigo + '.3V' + (proposta.cremacao ? '.CREMA': '');
                proposta.valor = tabelavalores.valor3vidasoumais 
    
            } else if (tabelavalores && proposta.quantidadeDeVidas >= 2) {
                proposta.tabela = tabelavalores.codigo + '.2V' + (proposta.cremacao ? '.CREMA': '');
                proposta.valor = tabelavalores.valor2vidas;
    
            } else if (tabelavalores) {
                proposta.tabela = tabelavalores.codigo + '.1V' + (proposta.cremacao ? '.CREMA': '');
                proposta.valor = tabelavalores.valor1vida;
    
            }
            proposta.adicional = (proposta.valor * (proposta.cremacao ? tabelavalores?.fatorcremacao: 1)) - proposta.valor


            //CLUBE DE DESCONTOS ---- PROPOMOCIONAL 0.0 para o titular
            proposta.adicional += (proposta.clubeDescontos ? 0 : 0);
            //CLUBE DE BENEFICIOS
            proposta.adicional += (proposta.clubeBeneficios ? tabelavalores.clubebeneficios : 0);

            proposta.valorTotalAdicional += proposta.adicional
            proposta.valorTotalMensal += proposta.valor + proposta.adicional
            proposta.mensalidade =  proposta.valorTotalMensal
    
            //proposta.valorTotalMensal += proposta.inscricao;
            // proposta.mensalidade += proposta.valorTotalMensal - proposta.valorTotalAdicional;
            
            let resumo = 'locale: ' + moment.locale() + '\n' +
                         'corretor: ' + (proposta.cpfCorretor ? proposta.cpfCorretor : 'SEM CORRETOR') + '\n\n' +
                         'data de inicio: '+ moment(dataDeInicio).format('L') + '\n' +
                         'qtd vidas: '+ proposta.quantidadeDeVidas + '\n' +
                         'plano: ' + proposta.plano + '\n' +
                         'categoria: ' + proposta.categoria + '\n' +
                         'cremação:' + (proposta.cremacao ? 'SIM': 'NÃO') + '\n' +
                         'taxa de inscrição: ' + this.obterValorFormatado(proposta.inscricao) + '\n' +
                         'mensalidade: ' + this.obterValorFormatado(proposta.mensalidade) + '\n' +
                         'total adicional: ' + this.obterValorFormatado(proposta.valorTotalAdicional) + '\n' +
                         'total mensal: ' + this.obterValorFormatado(proposta.valorTotalMensal) + '\n\n' +

                         'email: ' + proposta.email + '\n' +
                         'celular: ' + proposta.celular + '\n' +
                         'aceito contrato:' + (proposta.aceite ? 'SIM': 'NÃO') + '\n\n' +

                         'TITULAR \n' +
                         '   nome: ' + proposta.nome + '\n' +
                         '   nascimento: ' +  proposta.nascimento + '\n' +
                         '   idade: '+  proposta.idade + ' anos em ' + proposta.dataDeInicio + '\n' +
                         '   valor: '+  this.obterValorFormatado(proposta.valor) + ' ('+ proposta.tabela + ')\n' +
                         '   adicional: ' +  this.obterValorFormatado(proposta.adicional) + '\n' +
                         '   clube de descontos: ' + (proposta.clubeDescontos ? 'SIM': 'NÃO') + '\n' + 
                         '   clube de benefícios: ' + (proposta.clubeBeneficios ? 'SIM': 'NÃO')+ '\n\n';
    
            if(proposta.dependentes){
                proposta.dependentes.forEach(dependente => {
               
                    resumo += 'DEPENDENTE \n' +
                              '   nome: ' + dependente.nome + '\n' +
                              '   nascimento: ' + dependente.dataDeNascimento + '\n' +
                              '   idade: ' + dependente.idade + ' anos em ' + proposta.dataDeInicio + '\n' +
                              '   valor: ' + this.obterValorFormatado(dependente.valor) + ' (' + dependente.tabela + ')\n' +
                              '   adicional: ' + this.obterValorFormatado(dependente.adicional) + '\n' + 
                              '   clube de descontos: ' + (dependente.clubeDescontos ? 'SIM': 'NÃO') + '\n' + 
                              '   clube de benefícios: ' + (dependente.clubeBeneficios ? 'SIM': 'NÃO')+ '\n\n';
                });
            }
            

            if (proposta.arquivo){
                resumo += 'ARQUIVOS \n'
                // proposta.arquivos.forEach(arquivo => {
                    resumo += '   arquivo: ' + proposta.arquivo.fullpath + '\n'  
                // });
            }
            

            
            this.setState({resumo: resumo})
            this.setState({fields: proposta});
        } else {
            proposta.valor  = null;
            proposta.idade = null;
            proposta.adicional = null;
            proposta.mensalidade = null;
            proposta.valorTotalMensal = null;
            proposta.valorTotalAdicional = null;
        }
    }

    //REVISADO
    getErroBeneficiario(propriedade){
        //console.log(propriedade, 'errors:', this.state?.errors)
        try {
            let valor = this.state.errors[propriedade]
            //console.log('valor encontrado:', valor)
            return valor
        } catch (error) {
            //NAO EXISTE PROPRIEDADE DE ERRO 
            return 
        }
        return 
    }

    //REVISADO
    submitForm(e){
        e.preventDefault();
        
        //this.recalcularValores();
        let formularioValido = this.validaFormulario()
        this.atualizarValores(this.state.fields)

        //console.log(this.state.errors ? 'SIM': 'NAO');
        //console.log('submitForm... valido:', formularioValido, ' errors:', this.state.errors, ' len:', this.state.errors?.props?.length > 0);


        if (formularioValido) {
            //this.atualizarValores(this.state.fields)
            if (this.state.fields.status === 'novo'){
                this.incluirNoBanco(this.state.fields, 'enviado', true)
            } else {
                this.atualizarNoBanco(this.state.fields, 'enviado', true)
            }

        } else {
            this.growl.show({severity: 'error', summary: `Formulário inválido!`, detail: 'Por favor verifique os campos marcados (são obrigatórios) e tente novamente'});
        }
    }
    
    //REVISADO
    obtemTamanhoArquivoFormatado(bytes){
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return 'n/a';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i === 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }

    //NAO ESTA EM USO
    validateSize=(event)=>{
        let file = event.target.files[0];
        let size = 30000;
        let err = '';
        console.log(file.size);
        if (file.size > size) {
            err = file.type+' é muito grande, por favor use um arquivo menor\n';
        //toast.error(err);
     }
     return true
    };

    //REVISADO
    podeEditar() {
        let retorno = (this.state?.fields?.status !== 'enviado')
        //return true
        return retorno
    }

    //REVISADO
    getStateZerado(codigoDoCorretor){
        this.propostaId = undefined;

        let agora = new Date();
        let dInicio = moment(new Date(agora.getFullYear(), agora.getMonth(), 10));

        return {
            fields: {
                id : '',
                cpfCorretor: codigoDoCorretor,
                aceite: false,
                dataAceite: null,
                plano: 'F',
                categoria: 'RUBI',
                dataDeInicio: dInicio.format('L'),
                diaDePagamento: '10',
                nome: '',
                dataDeNascimento: null,
                sexo: null,
                estadoCivil: null,
                cpf: null,
                identidade: '',
                orgaoEmissor: '',
                profissao: '',
                enderecoResidencial: '',
                numeroResidencial: '',
                complementoResidencial: '',
                bairro: '',
                cidade: '',
                uf: '',
                cep: null,
                telefone: null,
                ramal: '',
                celular: null,
                email: '',
                valor: 0.00,
                idade: 0,
                adicional: 0.00,
                clubeDescontos: false,
                clubeBeneficios: false,
                dependentes: [
                    {
                    id: Guid(),
                    sexo: null,
                    estadoCivil: null,
                    parentesco: null,
                    cpf: '',
                    identidade: '',
                    orgaoEmissor: '',
                    profissao: '',
                    dataDeNascimento: null,
                    valor: 0.00,
                    idade: 0,
                    adicional: 0.00,
                    clubeDescontos: false,
                    clubeBeneficios: false,
                    }
                ],
                inscricao: 110.00, 
                mensalidade: 0.00,
                beneficioAdicional: 0.00,
                valorTotalMensal: 0.00,
                valorTotalAdicional: 0.00,
                tipoPagamento: 'MENSAL',
                observacoes: '',
                cremacao: false,
                displayQuestionDelete: false,
                displayIndexDelete: null, 
                status: 'novo',
                arquivo: null,
                arquivoSelecionado: null,
                isUploading: false,
                progress: 0,
            },
            errors: {},
            isUploading: false,
            progress: 0,
            exibirContrato: false,
            exibirInfoCategorias: false,
            exibirInfoClubeDescontos: false,
            exibirInfoClubeBeneficios: false
        };
    };

    //PENDENTE
    zeraState(){
        //console.log('zera state...')
        this.setState(this.getStateZerado());
        this.setState({propostaId: undefined});
    };

    obterData(stringFormatoBr){
        //console.log(stringFormatoBr) //12/07/1978
        if (stringFormatoBr !== undefined && stringFormatoBr !== null && stringFormatoBr.length === 10){
            var d = stringFormatoBr.split('/');
            var newdate = new Date(d[2], d[1], d[0]);
            if (newdate instanceof Date && !isNaN(newdate))
                return newdate;
        }

        return null;
        
    }


    //REVISADO
    enviarEmail(){

        //console.log('enviando email')
        var API_KEY = 'a5cd900060d11c0b88104e20ecf123ef-913a5827-549abd0f'
        var DOMAIN = 'sandbox52ed2ae281af4e70aa01b7de1220259b.mailgun.org';
        var mailgun = require('mailgun-js')({apiKey: API_KEY, domain: DOMAIN});

        const data = {
            from: 'Proposta <robert.libotti@gmail.com>',
            to: 'robert.libotti@gmail.com',
            cc: 'atendimento@abfassistencia.com.br',
            subject: ((this.state.fields.status === 'enviado') ? 'Proposta enviada: ' : 'Proposta em preenchimento: ') + this.state.fields.id,
            text: 'Proposta preenchida : https://proposta.abfassistencia.com.br/#/' + this.state.fields.id + '\n\n' +
                this.state.resumo 
                // 'TITULAR:' + '\n' +   
                // '  Nome: ' + this.state.fields.nome + '\n' +
                // '  CPF: ' + this.state.fields.cpf + '\n' +
                // '  NASCIMENTO: ' + this.state.fields.nascimento + '\n' +
                // '  IDADE: ' + this.state.fields.idade + ' anos em ' +  moment(this.obterData(this.state.fields.dataDeInicio)) + '\n' +
                // '\n' +
                // 'BENEFICIARIOS:' + '\n' +

                // '\n' +    
                // 'Data do aceite     -> ' + this.state.fields.dataAceite + '\n' +
                // 'Data de Inicio     -> ' + this.state.fields.dataDeInicio + '\n' +
                // 'Quantidade de vidas-> ' + this.state.fields.quantidadeDeVidas + '\n' +
                // 'Taxa de inscrição  -> ' + this.state.fields.inscricao + '\n' +
                // 'Mensalidade        -> ' + this.state.fields.mensalidade + '\n' +
                // 'Beneficio Adicional-> ' + this.state.fields.beneficioAdicional + '\n' +
                // 'Total              -> ' + this.state.fields.total

        };
        
        mailgun.messages().send(data, (error, body) => {
            console.log(body);
        });

    }

    //REVISADO
    incluirNoBanco(proposta, status, exibirMensagem){
        //console.log('incluirNoBanco', proposta, status, exibirMensagem)
        //GET A KEY
        const novaChave =  firebase.database().ref().child('propostas').push().key;
        //const novaChave = Guid()
        this.propostaId = novaChave

        proposta['id'] = novaChave;
        proposta['status'] = status;
        this.setState({fields: proposta});

        var updates = {}
        updates['/propostas/'+ novaChave] = proposta

        //console.log('nova chave:', novaChave, proposta)
        firebase.database().ref().update(updates).then(res => {
            if (exibirMensagem)
                this.growl.show({severity: 'success', detail: `Proposta incluida com sucesso!`, summary: 'Envio de Proposta'});
        
            this.enviarEmail()
        }).catch(error =>{
            this.growl.show({severity: 'error', detail: `Falha ao incluir proposta! ${error}`, summary: 'Envio de Proposta'});
        })
        
    }

    //REVISADO
    atualizarNoBanco(proposta, status, exibirMensagem){
        //console.log('ATUALIZAR', proposta.id, status, proposta)
        // this.pathjson = 'propostas/' + this.chave
        // this.ref = firebase.database().ref(this.pathjson)
 
        proposta['status'] = status;
        this.setState({fields: proposta});

        var updates = {}
        updates['/propostas/'+ proposta.id] = proposta
        return firebase.database().ref().update(updates).then(res => {
            if (exibirMensagem)
                this.growl.show({severity: 'success', detail: `Proposta atualizada com sucesso!`, summary: 'Atualização de Proposta'});
            
            this.enviarEmail()
        }).catch(error =>{
            this.growl.show({severity: 'error', detail: `Falha ao atualizar proposta! ${error}`, summary: 'Atualização de Proposta'});
        })

    }

    //REVISADO
    saveForm(){
        //console.log('saveForm...');
        //console.log('status:', this.state.fields.status)
        this.atualizarValores(this.state.fields)
        if (this.state.fields.status === 'novo'){
            //console.log('status novo... tentar incluir')
            this.incluirNoBanco(this.state.fields, 'salvo', true)
        } else {
            //console.log('status <> novo... tentar atualizar')
            this.atualizarNoBanco(this.state.fields, 'salvo', true)
        }
    }

    //REVISADO
    saveFormSemMensagens(){
        //console.log('saveFormSemMensagens...');
        this.atualizarValores(this.state.fields)
        if (this.state.fields.status === 'novo'){
            //console.log('status novo... tentar incluir (sem msg)');
            this.incluirNoBanco(this.state.fields, 'salvo', false);
        } else {
            //console.log('status <> novo... tentar atualizar (sem msg)');
            this.atualizarNoBanco(this.state.fields, 'salvo', false);
        }
        
    }

    onUploadHandler=(event) => {
        //console.log('onUpload')
    }
    //REVISADO
    uploaderHandler=(event)=> {
        //console.log('uploaderHandler:', event)
        let fields = this.state?.fields;

        let arqnovo = fields?.arquivo ? fields?.arquivo : null;
        let propostaId = fields?.id


        if (propostaId === undefined){
            //FORCAR SALVAMENTO PARA OBTER UM ID
            this.saveFormSemMensagens()
            propostaId = this.propostaId
        }

        //const idProposta = this.state?.fields?.id

        event.files.forEach(file => {
            var fileId = Guid()
            var filePath = '/users/' + firebase.auth().currentUser.uid + '/' + propostaId + '_' + fileId + '.' + file.name.split('.').pop()
            var storageRef = firebase.storage().refFromURL('gs://abf-propostas.appspot.com');

            var uploadTask = storageRef.child(filePath).put(file);

            uploadTask.on('state_changed', function(snapshot){
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    //console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    //console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  console.log('File available at', downloadURL);
                });
              });




            // storageRef.child(filePath).put(file).then(function(snapshot){
            //     console.log('Uploaded', snapshot.totalBytes, 'bytes.')
            //     return snapshot.ref.getDownloadURL().then((url) => { 
            //         console.log('registrando arquivo no state..')   
            //         arqnovo = ({
            //             id: fileId,
            //             fullpath: snapshot.metadata.fullPath,
            //             webpath: url,
            //             bytes: snapshot.totalBytes,
            //             friendlyName: file.name.split('/').pop()
            //             });

            //         fields.arquivo = arqnovo;
            //         fields.status = 'salvo';
        
            //         console.log('arq3:',arqnovo);
            //         console.log('fields3:', fields);

            //     });
            // });
        });

        //console.log('terminou:', fields)
        //SALVAR PARA FORCAR REBIND 
        this.growl.show({severity: 'success', detail: `Arquivo incluido com sucesso.`, summary: 'Inclusão de Arquivo'});
 
    }

    //REVISADO
    incluirBeneficiario(){
        const dep = this.state?.fields?.dependentes ? this.state.fields.dependentes : [];
        dep.push({
            id: Guid(),
            cpf: "",
            dataDeNascimento: null,
            estadoCivil: "",
            identidade: "",
            nome: "",
            orgaoEmissor: "",
            parentesco: "",
            profissao: "",
            sexo: "",
            valor: 0.00,
            clubeDescontos: false,
            clubeBeneficios: false});
        let fields = this.state.fields;
        fields.dependentes = dep;
        this.atualizarValores(fields);
        this.setState({fields: fields});

        this.growl.show({severity: 'success', detail: `Dependente incluido com sucesso. Por favor preencha adequadamente.`, summary: 'Inclusão de Dependente'});
    }

    //REVISADO
    exibirMensagemExclusaoDependente(indice) {
        this.setState({displayQuestionDelete: true})
        this.setState({displayIndexDelete: indice}) 
    }

    //REVISADO
    exibirMensagemExclusaoArquivo(indice) {
        this.setState({displayQuestionDeleteFile: true})
        this.setState({displayIndexDeleteFile: indice}) 
    }

    exibirContrato() {
        this.setState({exibirContrato: true})
    }

    exibirInfoCategorias() {
         this.setState({exibirInfoCategorias: true})
    }

    exibirInfoClubeDescontos() {
        this.setState({exibirInfoClubeDescontos: true})
   }

   exibirInfoClubeBeneficios() {
    this.setState({exibirInfoClubeBeneficios: true})
}

    //REVISADO
    getTituloSecao01(){
        if(!this.state?.fields?.id)
            return "Dados Contratuais"
        else
            return "Dados Contratuais" //+ this.state.id 
    }

    //REVISADO
    getSubTituloSecao01(){
        if (this.state?.fields?.id) {
            return "Código Proposta: " + this.state?.fields?.id + " <a href='/#/" + this.state?.fields?.id + "'><i class='pi pi-share-alt'></i></a>";
        }
        return ""  
    }

    //REVISADO
    validaFormulario(){
        //titular deve ser maior que >= 18 anos
        //plano categoria deve ser informado 

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //console.log('fields:', fields)

        if (!fields["aceite"]) {
            formIsValid = false;
            errors["aceite"] = "Aceite é Obrigatório";
        }

        if (!fields["nome"]) {
            formIsValid = false;
            errors["nome"] = "Nome é Obrigatório";
        }

        if (!fields["nascimento"]) {
            formIsValid = false;
            errors["nascimento"] = "Nascimento é Obrigatório";
        }

        if (!fields["plano"]) {
            formIsValid = false;
            errors["plano"] = "Plano é Obrigatório";
        }

        if (!fields["categoria"]) {
            formIsValid = false;
            errors["categoria"] = "Categoria é Obrigatória";
        }

        if (!fields["diaDePagamento"]) {
            formIsValid = false;
            errors["diaDePagamento"] = "Dia de Pagamento é Obrigatório";
        }

        // if (!fields["dataDeNascimento"]) {
        //     formIsValid = false;
        //     errors["dataDeNascimento"] = "Data de Nascimento é Obrigatória";
        // }

        if (!fields["sexo"]) {
            formIsValid = false;
            errors["sexo"] = "Sexo é Obrigatório";
        }

        if (!fields["estadoCivil"]) {
            formIsValid = false;
            errors["estadoCivil"] = "Estado Civil é Obrigatório";
        }

        if (!fields["cpf"]) {
            formIsValid = false;
            errors["cpf"] = "CPF é Obrigatório";
        }

        if (fields["cpf"] && !this.validarCPF(fields["cpf"])) {
            formIsValid = false;
            errors["cpf"] = "CPF inválido";
        }

        if (!fields["identidade"]) {
            formIsValid = false;
            errors["identidade"] = "Identidade é Obrigatória";
        }

        if (!fields["orgaoEmissor"]) {
            formIsValid = false;
            errors["orgaoEmissor"] = "Emissor é Obrigatória";
        }

        if (!fields["profissao"]) {
            formIsValid = false;
            errors["profissao"] = "Profissão é Obrigatória";
        }

        if (!fields["enderecoResidencial"]) {
            formIsValid = false;
            errors["enderecoResidencial"] = "Endereço é Obrigatório";
        }

        if (!fields["numeroResidencial"]) {
            formIsValid = false;
            errors["numeroResidencial"] = "Número é Obrigatório";
        }

        if (!fields["bairro"]) {
            formIsValid = false;
            errors["bairro"] = "Bairro é Obrigatório";
        }

        if (!fields["cidade"]) {
            formIsValid = false;
            errors["cidade"] = "Cidade é Obrigatória";
        }

        if (!fields["uf"]) {
            formIsValid = false;
            errors["uf"] = "UF é Obrigatória";
        }

        if (!fields["cep"]) {
            formIsValid = false;
            errors["cep"] = "CEP é Obrigatório";
        }

        if (!fields["celular"]) {
            formIsValid = false;
            errors["celular"] = "Celular é Obrigatório";
        }

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email é Obrigatório";
        }

        //console.log('ARQUIVO:', fields["arquivo"])
        if (!fields["arquivo"]) {
            formIsValid = false;
            errors["arquivo"] = "Envio de Documento é Obrigatório. ";
        // } else if (fields["arquivos"].length === 0){
        //     formIsValid = false;
        //     errors["arquivos"] = "Envio de Documento é Obrigatório.. ";
        }
        
        if (fields["dependentes"]){
            
            fields["dependentes"].map((beneficiario, index) => {
                if (!this.validaBeneficiario(beneficiario, index, errors ))
                    formIsValid = false;
            });


            //fields["dependentes"].forEach(this.validaBeneficiario){
            //  console.log('beneficiario ' + index + ':', beneficiario)
                // if(!beneficiario['nome']){
                //     form.formIsValid = false;
                //     errors["beneficiario_" + index + "_nome"] = "Nome é obrigatorio";
            //}
        }

        this.setState({errors: errors});
        //console.log('valido:', formIsValid, ' errors:', errors)
        // if (errors || !formIsValid){
        //     return false;
        // } else {
        //     return true;
        // }
        return formIsValid;

    }

    //REVISADO
    validaBeneficiario(value, index, errors) {
        var beneficiarioValido = true;
        
        if(!value['nome']){
            beneficiarioValido = false;
            errors["beneficiario_" + index + "_nome"] = "Nome é obrigatorio";
        }

        if(!value['dataDeNascimento']){
            beneficiarioValido = false;
            errors["beneficiario_" + index + "_dataDeNascimento"] = "Nascimento é obrigatorio";
        }

        if (value["cpf"] && !this.validarCPF(value["cpf"])) {
            beneficiarioValido = false;
            errors["beneficiario_" + index + "_cpf"] = "CPF inválido";
        }

        //console.log('beneficiario ', index, ' ', beneficiarioValido);
        return beneficiarioValido;
        //console.log('value:', value, ' index:', index, 'errors:', JSON.stringify(errors));
    }

    //REVISADO
    removerBeneficiario() {
        this.setState({displayQuestionDelete : false })
        //console.log('excluindo', this.state?.displayIndexDelete, this.state.displayIndexDelete)
        //if (this.state.displayIndexDelete){
            let fields = this.state.fields;
            let dependentes = fields.dependentes.slice();
            dependentes.splice(this.state.displayIndexDelete, 1);
            fields.dependentes = dependentes;
            this.setState({fields, fields});
        //}
    }

    //REVISADO
    removerArquivo() {
        this.setState({displayQuestionDeleteFile : false })
        //console.log('excluindo arquivo...', this.state?.displayIndexDeleteFile, this.state.fields.arquivo)
        //if (this.state.displayIndexDelete){
            //console.log('original', this.state.fields.arquivo)
            let fields = this.state.fields;
            // let arquivos = fields.arquivos.slice();
            // arquivos.splice(this.state.displayIndexDeleteFile, 1);
            // fields.arquivos = arquivos;
            fields.arquivo = null;
            this.setState({fields, fields});
            //console.log('arquivo:', this.state.fields.arquivo)
        //}
    }

    //REVISADO
    cancelarRemocao(){
        this.setState({displayQuestionDelete : false })
        //console.log('cancelando')
    }

    //REVISADO
    cancelarRemocaoArquivo(){
        this.setState({displayQuestionDeleteFile : false })
        //console.log('cancelando')
    }

    esconderContrato(){
        this.setState({exibirContrato : false })
        //console.log('cancelando')
    }

    esconderInfoCategorias(){
        //console.log('escondendo')
        this.setState({exibirInfoCategorias : false })
    }

    esconderInfoClubeDescontos(){
        //console.log('escondendo')
        this.setState({exibirInfoClubeDescontos : false })
    }

    esconderInfoClubeBeneficios(){
        //console.log('escondendo')
        this.setState({exibirInfoClubeBeneficios : false })
    }

    //REVISADO
    handleBeneficiariosChange(index, propriedade, event) {
        //console.log('index:', index, ',propriedade:', propriedade, ',value:', event.target.value, '...');

        if (event.target.value && event.target.value !== undefined && event.target.value !== null && event.target.value !== true && event.target.value !== false)
            event.target.value = event.target.value.toUpperCase();

        let fields = this.state.fields;
        let dependentes = fields.dependentes.slice()
        dependentes[index][propriedade] = event.target.value
        
        fields.dependentes = dependentes;
        this.setState({
          fields
        });

        if ((propriedade === 'dataDeNascimento' && this.obterData(event.target.value) != null)  || propriedade === 'clubeDescontos' || propriedade === 'clubeBeneficios') 
            this.atualizarValores(fields)
    }
    
    //REVISADO
    obterNomeBeneficiario(indice){
        if (this.state?.fields.dependentes){
            let dependentes = this.state.fields.dependentes.slice()
            let nome = dependentes[indice]?.nome
            return nome ? "(" + nome.toString().toUpperCase() + ")": "(Nome Não Digitado)"
        } 
        return ""
    }

    //REVISADO
    obterNomeArquivo(indice){
        if (this.state?.fields.arquivo){
            let arquivo = this.state.fields.arquivo; //.slice()
            let nome = arquivo?.friendlyName
            return nome ? "(" + nome.toString().toUpperCase() + ")": "(arquivo sem descricao)"
        } 
        return ""
    }

    

    render() {
        
        const footer = (
            <div>
                <Button className="p-button p-component p-button-danger" label="Sim" icon="pi pi-check" onClick={this.removerBeneficiario} />
                <Button label="Não" icon="pi pi-times" onClick={this.cancelarRemocao} />
            </div>
        );

        const footerFile = (
            <div>
                <Button className="p-button p-component p-button-danger" label="Sim" icon="pi pi-check" onClick={this.removerArquivo} />
                <Button label="Não" icon="pi pi-times" onClick={this.cancelarRemocaoArquivo} />
            </div>
        );

        const footerInfoCategorias = (
            <div>
                <Button label="Fechar" icon="pi pi-times" onClick={this.esconderInfoCategorias} />
            </div>
        );

        const footerInfoClubeDescontos = (
            <div>
                <Button label="Fechar" icon="pi pi-times" onClick={this.esconderInfoClubeDescontos} />
            </div>
        );

        const footerInfoClubeBeneficios = (
            <div>
                <Button label="Fechar" icon="pi pi-times" onClick={this.esconderInfoClubeBeneficios} />
            </div>
        );

        const rodapeContrato = (
            
            <div className="p-grid p-fluid">
                <div className="p-col-9" style={{textAlign: 'left'}}>
                    Você receberá uma cópia do seu contrato por email assim que sua proposta for analisada e aceita.
                </div>
                <div className="p-col-3">
                    
                    {/* <ReactToPrint 
                        trigger={() => <Button className="p-button-secondary"  label="Imprimir modelo" />}
                        content={() => this.componentRef}
                        /> */}
                    <a target="_new" href="/assets/contrato/MODELO_V202004.pdf"><Button className="p-button-secondary" label="Imprimir Modelo" onClick={this.esconderContrato} /></a>
                    
                    <Button label="Ok" onClick={this.esconderContrato} />
                    
                </div>
                <div className="p-col-1">
                    
                </div>
            </div>
        );

        return (
          
            <div className="p-grid p-fluid">
                <Growl ref={(el) => {this.growl = el}}></Growl>
                <Dialog header="Atenção" position={'right'} footer={footer}  visible={this.state?.displayQuestionDelete} style={{width: '50vw'}} modal={true} onHide={() => this.setState({displayQuestionDelete: false})}>
                    Deseja realmente remover o beneficiario {this.state?.displayIndexDelete + 1} - {this.obterNomeBeneficiario(this.state?.displayIndexDelete)} ?
                </Dialog>
                <Dialog header="Atenção" position={'right'} footer={footerFile}  visible={this.state?.displayQuestionDeleteFile} style={{width: '50vw'}} modal={true} onHide={() => this.setState({displayQuestionDeleteFile: false})}>
                    Deseja realmente remover o arquivo {this.state?.displayIndexDeleteFile + 1} - {this.obterNomeArquivo(this.state?.displayIndexDeleteFile)} ?
                </Dialog>
                <Dialog header="Informação das Categorias" position={'center'} footer={footerInfoCategorias}  visible={this.state?.exibirInfoCategorias} style={{width: '80vw'}} modal={true} onHide={() => this.setState({exibirInfoCategorias: false})}>
                    <div>
                        <DataTable value={this.propostaService.util.servico}>
                            <Column field="descricao" header="" />
                            <Column field="rubi" header="Rubi" />
                            <Column field="safira" header="Safira" />
                            <Column field="ouro" header="Ouro" />
                        </DataTable>
                    </div>
                </Dialog>
                <Dialog header="Clube de Descontos" position={'center'} footer={footerInfoClubeDescontos}  visible={this.state?.exibirInfoClubeDescontos} style={{width: '75vw'}} modal={true} onHide={() => this.setState({exibirInfoClubeDescontos: false})}>
                    <div>
                        <a href="https://cartaomasterclin.com.br/clientes/hotsite/abfassistencia" target="new"><img src="/assets/contrato/clube-de-desconto.png" alt="clube de desconto" /></a>
                    </div>
                </Dialog>
                <Dialog header="Clube de Beneficios" position={'center'} footer={footerInfoClubeBeneficios}  visible={this.state?.exibirInfoClubeBeneficios} style={{width: '73vw'}} modal={true} onHide={() => this.setState({exibirInfoClubeBeneficios: false})}>
                    <div>
                    <img src="/assets/contrato/clube-de-beneficios.png" style={{width : '70vw'}} alt="clube de beneficios" />
                    </div>
                </Dialog>
                <div className="p-col-12">
                  {/* <form method="post" onSubmit={this.submitForm} noValidate> */}
                    <div className="card card-w-title">
                        <div className='p-card-title'>{this.getTituloSecao01()}</div>
                        <div className='p-card-subtitle'>
                        {/* Código Proposta: {this.state?.fields?.id} <a href={('/#/' + this.state?.fields?.id)} target='new'><i className='pi pi-share-alt'></i></a> */}
                        
                        {(this.state?.fields?.id) ? 
                            ReactHtmlParser(`Código Proposta: ${this.state?.fields?.id} <a href='${('/#/' + this.state?.fields?.id)}' target='new'><i class='pi pi-share-alt'></i></a>`)
                        : 'Proposta não salva'}
                             
                            {/* a href='/#/{this.state?.fields?.id}' &lt;&gt;gt;&lt;i className='pi pi-share-alt' &gt; &lt;/i&gt;&lt;/a&gt; */}
                            {  // this.state?.fields?.id) ? 
                                //"Código Proposta: " + this.state?.fields?.id + " "
                            //: null
                            }
                        </div>
                        <div className="p-grid">

                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                <Dropdown id="plano" name="plano" autoDisplayFirst={false}  
                                        disabled={!this.podeEditar()}
                                        options={this.propostaService.util.planos} 
                                        value={this.state?.fields?.plano} 
                                        onChange={this.handleChange} 
                                        autoWidth={false}
                                        className={this.state?.errors?.plano ? "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus p-error": "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus"}
                                        placeholder="Selecione"
                                        />
                                <label htmlFor="plano">Plano</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <Dropdown id="categoria" name="categoria"
                                        disabled={!this.podeEditar()}
                                        options={this.propostaService.util.categorias} 
                                        value={this.state?.fields.categoria} 
                                        onChange={this.handleChange} 
                                        className={this.state?.errors?.categoria ? "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus p-error": "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus"}
                                        autoWidth={false} 
                                        placeholder="Selecione"/>
                                    <label>Categoria</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 20 + 'px'}}>
                                <a href="./#" onClick={this.exibirInfoCategorias} alt="comparar categorias"><i className='pi pi-question-circle' style={{fontSize: 2 + 'em'}}></i></a>
                            </div>
                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <Dropdown id="diaDePagamento" name="diaDePagamento" 
                                        disabled={!this.podeEditar()}
                                        options={this.propostaService.util.diasDePagamentos} 
                                        value={this.state?.fields?.diaDePagamento} 
                                        onChange={this.handleChange} 
                                        className={this.state?.errors?.diaDePagamento ? "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus p-error": "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus"}
                                        autoWidth={false} 
                                        placeholder="Selecione"
                                        />
                                    <label htmlFor="comboDiaDePagamento">Dia de Pagamento</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-md-1  col-lg-1" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputSwitch id="cremacao" name="cremacao"
                                        disabled={!this.podeEditar()}
                                        checked={this.state?.fields?.cremacao} 
                                        onChange={this.handleChange} 
                                        className='p-inputwrapper-filled p-input-wrapper-focus '
                                    />
                                    <label htmlFor="cremacao"> Cremação?</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-md-1  col-lg-1" style={{marginTop: 15 + 'px'}}>
                            </div>
                            <div className="p-col-12 p-md-2  col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    { this.state?.fields?.cpfCorretor ? <InputText disabled={true} readOnly={true} style={{borderWidth: 1 + 'px', color: 'darkred', backgroundColor: '#f4b7b7'}} id="cpfCorretor" type="text"  size={9} value={this.state?.fields?.cpfCorretor} /> : null}
                                    { this.state?.fields?.cpfCorretor ? <label htmlFor="cpfCorretor"> Corretor</label> : null}
                                </span>
                            </div>
                            <div className="p-col-12 p-md-6  col-lg-6" >
                                <div aria-live="polite" className="p-message p-component p-message-warn" role="alert">
                                    <span className="p-message-icon pi pi-fw pi-exclamation-triangle"></span>
                                    <span className="p-message-text">Categoria Safira e opcional de cremação estão disponíveis apenas no município do Rio de Janeiro.</span>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6  col-lg-6" >
                                <div aria-live="polite" className="p-message p-component p-message-info" role="alert">
                                    <span className="p-message-icon pi pi-fw pi-info-circle"></span>
                                    <span className="p-message-text">Os valores são alterados em função do plano, categoria, quantidade de beneficiários e idades.</span>
                                </div>                               
                            </div>


                        </div>
                    </div>
                    <div className="card card-w-title">

                        <div className='p-card-title'>Dados do Titular
                            {/* <div className='p-card-subtitle' style={{ fontSize: '12px', float: 'right', margin: '0px 10px 0 0' }}>
                                <Button className='p-button-secondary p-button-rounded p-disabled' label={'Custo Estimado do Titular (R$): ' + this.state.valor} icon="pi pi-info-circle" tooltip='Baseado no plano escolhido e idade do titular' tooltipOptions={{position: 'bottom'}} />
                            </div> */}
                        </div>

                        <div className='p-grid'>
                            <div className="p-col-12 p-md-4 col-lg-4 p-error"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="nome" name='nome' type="text" required={true} maxLength={100} size={100} value={this.state?.fields?.nome} onChange={this.handleChange} className={this.state?.errors?.nome ? "p-error": null} />
                                    <label htmlFor="nome" className={this.state?.errors?.nome ? "l-error": null}>Nome</label>
                                </span>
                            </div>
                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputMask disabled={!this.podeEditar()} id="nascimento" name="nascimento" required={true} mask="99/99/9999" type="text" size={10} value={this.state?.fields?.nascimento} onChange={this.handleChange} className={this.state?.errors?.nascimento ? "p-error": null}/>
                                    <label className={this.state?.errors?.nascimento ? "l-error": null}>Nascimento</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputMask disabled={!this.podeEditar()} id="cpf" name='cpf' required={true} mask="999.999.999-99" type="text" size={14} value={this.state?.fields?.cpf} onChange={this.handleChange} className={this.state?.errors?.cpf ? "p-error": null}/>
                                    <label className={this.state?.errors?.cpf ? "l-error": null}>CPF</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="identidade" name="identidade" maxLength={15} type="text" required={true} size={15} value={this.state?.fields?.identidade} onChange={this.handleChange} className={this.state?.errors?.identidade ? "p-error": null} />
                                    <label className={this.state?.errors?.identidade ? "l-error": null}>Identidade</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="orgaoEmissor" name="orgaoEmissor" maxLength={15} type="text" size={15} value={this.state?.fields?.orgaoEmissor} onChange={this.handleChange} className={this.state?.errors?.orgaoEmissor ? "p-error": null} />
                                    <label className={this.state?.errors?.orgaoEmissor ? "l-error": null}>Orgão Emissor</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                
                                <span className='p-float-label'>
                                    <Dropdown id="sexo" name="sexo" 
                                        disabled={!this.podeEditar()}
                                        options={this.propostaService.util.sexos} 
                                        value={this.state?.fields?.sexo} 
                                        onChange={this.handleChange} 
                                        autoWidth={true} 
                                        placeholder="Selecione"
                                        className={this.state?.errors?.sexo ? "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus p-error": "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus"}
                                        />
                                    <label htmlFor="sexo" className={this.state?.errors?.sexo ? "l-error": null}>Sexo</label>
                                </span>
                               
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                
                                <span className='p-float-label'>
                                    <Dropdown id="estadoCivil" name="estadoCivil"
                                        disabled={!this.podeEditar()}
                                        options={this.propostaService.util.estadosCivis} 
                                        value={this.state?.fields?.estadoCivil} 
                                        onChange={this.handleChange} 
                                        autoWidth={true} 
                                        placeholder="Selecione"
                                        className={this.state?.errors?.estadoCivil ? "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus p-error": "p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus"}
                                        />
                                    <label htmlFor="estadoCivil" className={this.state?.errors?.estadoCivil ? "l-error": null}>Estado Civil</label>
                                </span>
                               
                            </div>
                            
                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="profissao" name="profissao" maxLength={15}  type="text" size={15} value={this.state?.fields?.profissao} onChange={this.handleChange}  className={this.state?.errors?.profissao ? "p-error": null}/>
                                    <label htmlFor="profissao" className={this.state?.errors?.profissao ? "l-error": null}>Profissão</label>
                                </span>
                            </div>
                            
                            <div className="p-col-12 p-md-4 col-lg-4"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="enderecoResidencial" name="enderecoResidencial" type="text" size={40} maxLength={40} value={this.state?.fields?.enderecoResidencial} onChange={this.handleChange} className={this.state?.errors?.enderecoResidencial ? "p-error": null}/>
                                    <label htmlFor="enderecoResidencial" className={this.state?.errors?.enderecoResidencial ? "l-error": null}>Endereço Residencial</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="numeroResidencial" name="numeroResidencial" type="text" size={6} maxLength={6} value={this.state?.fields?.numeroResidencial} onChange={this.handleChange} className={this.state?.errors?.numeroResidencial ? "p-error": null}/>
                                    <label htmlFor="numeroResidencial" className={this.state?.errors?.numeroResidencial ? "l-error": null}>Número</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="complementoResidencial" name="complementoResidencial" type="text" size={20} maxLength={20} value={this.state?.fields?.complementoResidencial} onChange={this.handleChange} className={this.state?.errors?.complementoResidencial ? "p-error": null}/>
                                    <label htmlFor="complementoResidencial" className={this.state?.errors?.complementoResidencial ? "l-error": null}>Complemento</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="bairro" name="bairro" maxLength={20} type="text" size={20} value={this.state?.fields?.bairro} onChange={this.handleChange} className={this.state?.errors?.bairro ? "p-error": null}/>
                                    <label htmlFor="bairro" className={this.state?.errors?.bairro ? "l-error": null}>Bairro</label>
                                </span>
                            </div>
                            
                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="cidade" name="cidade" type="text" maxLength={20} size={20} value={this.state?.fields?.cidade} onChange={this.handleChange} className={this.state?.errors?.cidade ? "p-error": null}/>
                                    <label htmlFor="cidade" className={this.state?.errors?.cidade ? "l-error": null}>Cidade</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={!this.podeEditar()} id="uf" name="uf" type="text" maxLength={2} size={2} value={this.state?.fields?.uf} onChange={this.handleChange} className={this.state?.errors?.uf ? "p-error": null}/>
                                    <label htmlFor="uf" className={this.state?.errors?.uf ? "l-error": null}>UF</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputMask disabled={!this.podeEditar()} id="cep" name="cep" maxlength={15} mask="99.999-999" type="text" size={15} value={this.state?.fields?.cep} onChange={this.handleChange} className={this.state?.errors?.cep ? "p-error": null}/>
                                    <label htmlFor="cep" className={this.state?.errors?.cep ? "l-error": null}>CEP</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputMask disabled={!this.podeEditar()} id="telefone" name="telefone" mask="(99) 9999-9999" type="text" maxLength={15} size={15} value={this.state?.fields?.telefone} onChange={this.handleChange} className={this.state?.errors?.telefone ? "p-error": null}/>
                                    <label htmlFor="telefone" className={this.state?.errors?.telefone ? "l-error": null}>Telefone Fixo</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText  disabled={!this.podeEditar()}  id="ramal" name="ramal" type="text" maxLength={15} size={15} value={this.state?.fields?.ramal} onChange={this.handleChange} className={this.state?.errors?.ramal ? "p-error": null}/>
                                    <label htmlFor="ramal" className={this.state?.errors?.ramal ? "l-error": null}>Ramal</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputMask  disabled={!this.podeEditar()}  id="celular" name="celular" mask="(99) 99999-9999" maxLength={15} type="text" size={15} value={this.state?.fields?.celular} onChange={this.handleChange} className={this.state?.errors?.celular ? "p-error": null}/>
                                    <label htmlFor="celular" className={this.state?.errors?.celular ? "l-error": null}>Celular</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-4 col-lg-4"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText  disabled={!this.podeEditar()} id="email" name="email" type="email" maxLength={100} size={100} value={this.state?.fields?.email} onChange={this.handleChange} className={this.state?.errors?.email ? "p-error": null}/>
                                    <label htmlFor="email" className={this.state?.errors?.email ? "l-error": null}>Email</label>
                                </span>
                            </div>

                            <div className="p-col-12 p-md-4 col-lg-4"  style={{marginTop: 15 + 'px'}}>
                            </div>

                            {/* <div className="p-col-12 p-md-6 col-lg-6"  style={{marginTop: 15 + 'px'}}>
                                <span className="label">
                                    <InputSwitch disabled={!this.podeEditar()} id="clubeDescontos" name="clubeDescontos" checked={this.state?.fields?.clubeDescontos} onChange={this.handleChange} />
                                    <label style={{fontSize: 12 + 'px'}}> Clube de Descontos <a onClick={this.exibirInfoClubeDescontos} alt="clube de descontos"><i className='pi pi-question-circle' style={{fontSize: 1.2 + 'em'}}></i></a></label>
                                    <br/>
                                    <label style={{fontSize: 12 + 'px'}} className="l-error"> * GRATUITO para titulares de novos contratos </label>
                                </span>
                            </div> */}

                            {/* <div className="p-col-12 p-md-6 col-lg-6"  style={{marginTop: 15 + 'px'}}>
                                <span className="label">
                                    <InputSwitch disabled={!this.podeEditar()} id="clubeBeneficios" name="clubeBeneficios" checked={this.state?.fields?.clubeBeneficios} onChange={this.handleChange} />
                                    <label style={{fontSize: 12 + 'px'}}> Clube de Benefícios <a onClick={this.exibirInfoClubeBeneficios} alt="clube de beneficios"><i className='pi pi-question-circle' style={{fontSize: 1.2 + 'em'}}></i></a></label>
                                    <br/>
                                    <label style={{fontSize: 12 + 'px'}} className="l-error"> * 7,50/mês </label>
                                </span>
                            </div> */}
                            
                        </div>
                    </div>

                    <div className="card card-w-title" style={{display: (this.state?.fields?.plano === 'I') ? 'none' : 'block'}}>

                        <div className='p-card-title'>Dados dos Beneficiários {this.podeEditar() ? <Button icon="pi pi-user-plus" label="" className="p-button p-component p-button-rounded p-button-success p-button-text-only" onClick={this.incluirBeneficiario}></Button> : null} </div>
                        
                            {(this.state?.fields?.dependentes || []).map((beneficiario, index) => (
                                <div className='p-card-content' key={index} >
                                    <div className="p-grid">
                                        <div className="p-col-1 p-col-align-center" style={{textAlign: 'center', verticalAlign:'middle'}}>
                                            <h2>{index + 1}. {this.podeEditar() ? <Button key={index} icon="pi pi-user-minus" label="" className="p-button p-component p-button-rounded p-button-danger p-button-text-only" onClick={() => {this.exibirMensagemExclusaoDependente(index)}}></Button> : null}</h2>
                                        </div>

                                        <div className="p-col-11">
                                            <div className="p-grid">
                                                
                                                <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                                    <span className="p-float-label">
                                                        <InputText 
                                                            name={'nome'} 
                                                            disabled={!this.podeEditar()}
                                                            type="text" 
                                                            required={true} 
                                                            maxLength={100}
                                                            size={100} 
                                                            value={beneficiario.nome} 
                                                            onChange={this.handleBeneficiariosChange.bind(this, index, 'nome') }
                                                            className={this.getErroBeneficiario('beneficiario_' + index + '_nome') ? "p-error": null}
                                                        />
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_nome') ? "l-error": null}>Nome</label>
                                                    </span>
                                                </div>
                                                <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                                    <span className="p-float-label">
                                                        <InputMask 
                                                            name={'dataDeNascimento'}
                                                            disabled={!this.podeEditar()} 
                                                            key={beneficiario.id}  
                                                            mask="99/99/9999"  
                                                            type="text" size={10} 
                                                            required={true}
                                                            value={beneficiario.dataDeNascimento} 
                                                            onChange={this.handleBeneficiariosChange.bind(this, index, 'dataDeNascimento')}
                                                            className={this.getErroBeneficiario('beneficiario_' + index + '_dataDeNascimento') ? "p-error": null}
                                                        />
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_dataDeNascimento') ? "l-error": null}>Nascimento</label>
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                                    <span className="p-float-label">
                                                        <InputMask 
                                                            disabled={!this.podeEditar()} 
                                                            key={beneficiario.id}  
                                                            mask="999.999.999-99" 
                                                            type="text" 
                                                            size={14} 
                                                            value={beneficiario.cpf} 
                                                            onChange={this.handleBeneficiariosChange.bind(this, index, 'cpf')} 
                                                            className={this.getErroBeneficiario('beneficiario_' + index + '_cpf') ? "p-error": null}
                                                        />
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_cpf') ? "l-error": null}>CPF</label>
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                                    <span className="p-float-label">
                                                        <InputText  disabled={!this.podeEditar()}  key={beneficiario.id}  type="text" size={15} maxLength={15} value={beneficiario.identidade} onChange={this.handleBeneficiariosChange.bind(this, index, 'identidade')}/>
                                                        <label>Identidade</label>
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                                    <span className="p-float-label">
                                                        <InputText  disabled={!this.podeEditar()}  key={beneficiario.id}  type="text" size={60} maxLength={15} value={beneficiario.orgaoEmissor} onChange={this.handleBeneficiariosChange.bind(this, index, 'orgaoEmissor') }/>
                                                        <label>Orgão Emissor</label>
                                                    </span>
                                                </div> 

                                                <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                                    <span className='p-float-label'>
                                                        <Dropdown key={beneficiario.id}
                                                            disabled={!this.podeEditar()}  
                                                            options={this.propostaService.util.sexos} 
                                                            value={beneficiario.sexo} 
                                                            onChange={this.handleBeneficiariosChange.bind(this, index, 'sexo') } 
                                                            autoWidth={true} 
                                                            placeholder="Selecione"
                                                            className="p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus "
                                                            />
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_sexo') ? "l-error": null}>Sexo</label>
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                                    <span className='p-float-label'>
                                                        <Dropdown id="estadoCivil" 
                                                            disabled={!this.podeEditar()}
                                                            options={this.propostaService.util.estadosCivis} 
                                                            value={beneficiario.estadoCivil} 
                                                            onChange={this.handleBeneficiariosChange.bind(this, index, 'estadoCivil') } 
                                                            autoWidth={true} 
                                                            placeholder="Selecione"
                                                            className="p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus "
                                                            />
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_estadoCivil') ? "l-error": null}>Estado Civil</label>
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                                    <span className="p-float-label">
                                                        <InputText disabled={!this.podeEditar()} key={beneficiario.id}  type="text" size={15} maxLength={15}  value={beneficiario.profissao} 
                                                        onChange={this.handleBeneficiariosChange.bind(this, index, 'profissao')}/>
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_profissao') ? "l-error": null}>Profissão</label>
                                                    </span>
                                                </div>

                                                <div className="p-col-12 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                                    <span className='p-float-label'>
                                                        <Dropdown key={beneficiario.id}
                                                            disabled={!this.podeEditar()}  
                                                            options={this.propostaService.util.parentescos} 
                                                            value={beneficiario.parentesco} 
                                                            onChange={this.handleBeneficiariosChange.bind(this, index, 'parentesco') } 
                                                            autoWidth={true} 
                                                            placeholder="Selecione"
                                                            className="p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus "
                                                            />
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_parentesco') ? "l-error": null}>Parentesco</label>
                                                    </span>
                                                </div>

                                                {/* <div className="p-col-12 p-md-3 col-lg-3" style={{marginTop: 15 + 'px'}}>
                                                    <span className='label'>
                                                        <InputSwitch key={beneficiario.id} checked={beneficiario.clubeDescontos} onChange={this.handleChange} />
                                                        <label className={this.getErroBeneficiario('beneficiario_' + index + '_clubedescontos') ? "l-error": null}>Clube Descontos (R$3,99/mês)</label>
                                                    </span>
                                                </div> */}

                                                {/* <div className="p-col-12 p-md-6 col-lg-6" style={{marginTop: 15 + 'px'}}>
                                                    <span className='label'>
                                                        <InputSwitch disabled={!this.podeEditar()} key={beneficiario.id} checked={beneficiario.clubeBeneficios} onChange={this.handleBeneficiariosChange.bind(this, index, 'clubeBeneficios') }  /> 
                                                        <label style={{fontSize: 12 + 'px'}}> Clube de Benefícios (7,50/mês) <a onClick={this.exibirInfoClubeBeneficios} alt="clube de beneficios"><i className='pi pi-question-circle' style={{fontSize: 1.2 + 'em'}}></i></a></label>
                                                    </span>
                                                </div> */}

                                            </div>
                                        </div>


                                    </div>
                                    
                                </div>
                            ))}

                    </div>

                    <div className="card card-w-title">

                        <div className='p-card-title'>Condições de Pagamento
                            {/* <div className='p-card-subtitle' style={{ fontSize: '12px', float: 'right', margin: '0px 10px 0 0' }}>
                                <Button className='p-button-secondary p-button-rounded p-disabled' label={'Custo Estimado do Titular (R$): ' + this.state.valor} icon="pi pi-info-circle" tooltip='Baseado no plano escolhido e idade do titular' tooltipOptions={{position: 'bottom'}} />
                            </div> */}
                        </div>

                        <div className='p-grid' >
                            <div className="p-col-3 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label" >
                                    <InputText disabled={true} readOnly={true} style={{borderWidth: 0 + 'px'}} id="dataDeInicio" type="text"  size={10} value={this.state?.fields?.dataDeInicio} />
                                    <label>Dt Início</label>
                                </span>
                            </div>
                            <div className="p-col-3 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label" >
                                    <InputText disabled={true} readOnly={true} style={{borderWidth: 0 + 'px'}} id="inscricao" type="text"  size={10} value={new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL"
                                    }).format(this.state?.fields?.inscricao)} />
                                    <label>Tx de Adesão</label>
                                </span>
                            </div>

                            <div className="p-col-3 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={true} readOnly={true} style={{borderWidth: 0 + 'px'}} id="mensalidade" type="text"  size={10} value={new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL"
                                    }).format(this.state?.fields?.mensalidade)} />
                                    <label>Mensalidade</label>
                                </span>
                            </div>

                            <div className="p-col-3 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={true} readOnly={true} style={{borderWidth: 0 + 'px'}} id="valorTotalAdicional" type="text"  size={10} value={new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL"
                                    }).format(this.state?.fields?.valorTotalAdicional)} />
                                    <label>Benef. Adicional</label>
                                </span>
                            </div>
                        
                            <div className="p-col-3 p-md-2 col-lg-2"  style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputText disabled={true} readOnly={true} style={{borderWidth: 0 + 'px'}} id="valorTotalMensal" type="text"  size={10} value={new Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL"
                                    }).format(this.state?.fields?.valorTotalMensal)}/>
                                    <label>Total</label>
                                </span>
                            </div>

                            <div className="p-col-6 p-md-2 col-lg-2" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                <Dropdown readOnly={true} disabled={true} id="tipoPagamento" autoDisplayFirst={false}
                                        style={{borderWidth: 0 + 'px'}}  
                                        options={this.propostaService.util.tiposDePagamentos} 
                                        value={this.state?.fields?.tipoPagamento} 
                                        //onChange={this.onPlanoChange}
                                        onChange={this.handleChange} 
                                        autoWidth={false}
                                        className="p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus " 
                                        //p-dropdown p-component p-inputwrapper-filled p-input-wrapper-focus
                                        placeholder="Selecione"
                                        />
                                <label>Tipo de Pagamento</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-12  col-lg-12">
                                <div aria-live="polite" className="p-message p-component p-message-info" role="alert">
                                    <span className="p-message-icon pi pi-fw pi-info-circle"></span>
                                    <span className="p-message-text"> O valor da taxa de adesão será cobrado no ato da entrega da documentação.</span>
                                </div>                               
                        </div>
                    </div>

                    <div className="card card-w-title">

                        <div className='p-card-title'>Carências
                            {/* <div className='p-card-subtitle' style={{ fontSize: '12px', float: 'right', margin: '0px 10px 0 0' }}>
                                <Button className='p-button-secondary p-button-rounded p-disabled' label={'Custo Estimado do Titular (R$): ' + this.state.valor} icon="pi pi-info-circle" tooltip='Baseado no plano escolhido e idade do titular' tooltipOptions={{position: 'bottom'}} />
                            </div> */}
                        </div>

                        <div className='p-grid'>
                            <Card subTitle="Até 60 anos:" className='p-col-6 p-md-2 col-lg-2'>
                                90 dias após o 1° pagamento bancário
                            </Card>
                            <Card subTitle="De 61 a 79 anos:" className='p-col-6 p-md-2 col-lg-2'>
                                180 dias após o 1° pagamento bancário
                            </Card>
                            <Card subTitle="A partir de 80 anos" className='p-col-6 p-md-2 col-lg-2'>
                                300 dias após o 1° pagamento bancário
                            </Card>
                            <Card subTitle="Cremação" className='p-col-6 p-md-2 col-lg-2'>
                                365 dias após o 1° pagamento bancário
                            </Card>
                            <Card className='p-col-12 p-md-4 col-lg-4'>
                                <span className='pi pi-info-circle'></span> Antecipações de pagamento não são consideradas para efeito de cálculo de carência.
                            </Card>

                            
                        </div>
                    </div>

                    {/* <div className="card card-w-title">

                        <div className='p-card-title'>Observações</div>

                        <div className='p-grid'>
                            <div className="p-col-12 p-md-12 col-lg-12" style={{marginTop: 15 + 'px'}}>
                                <span className="p-float-label">
                                    <InputTextarea readOnly={!this.podeEditar()} id="observacoes" type="text" maxLength={1000} rows="4" size={1000} value={this.state?.fields?.observacoes} onChange={this.handleChange} />
                                    <label htmlFor="observacoes">Observações Gerais (se houver) : {this.state?.fields.observacoes.length}/1000 caracteres</label>
                                </span>
                            </div>
                        </div>
                    </div> */}

                    <div className="card card-w-title">

                        <div className='p-card-title'>Foto + Documento</div>

                        <div className='p-grid'>



                            <div className="p-col-12 p-md-10 col-lg-10">
                                <label></label>
                                <label className={this.state?.errors?.arquivo ? "l-error": null}>{this.state?.errors?.arquivo} Confirme a sua identidade com uma foto sua segurando o documento (identidade com CPF ou carteira de motorista com foto).</label>
                                {/* <p>{JSON.stringify(this.state.fields)}</p> */}
                            </div>

                            <div className="p-col-12 p-md-2 col-lg-2">
                                {/* {this.podeEditar() ?  <FileUpload  id="arquivo" name="arquivo" mode="basic" accept="image/*" url="./upload" maxFileSize={10000000} customUpload={true} uploadHandler={this.uploaderHandler} onUpload={this.onUploadHandler} chooseLabel='selecionar' uploadLabel='enviar' cancelLabel='cancelar' auto={true} invalidFileSizeMessageSummary="{0}: tamanho inválido" invalidFileSizeMessageDetail="tamanho máximo permitido: {0}." /> : null } */}
                                {this.state?.isUploading && <p>Progresso: {this.state?.progress} %</p>}

                                {/* var filePath = '/users/' + firebase.auth().currentUser.uid + '/' + propostaId + '_' + fileId + '.' + file.name.split('.').pop()
            var storageRef = firebase.storage().refFromURL('gs://abf-propostas.appspot.com'); */}
                                {(!this.state?.fields?.arquivo && !this.state?.isUploading) ? 
                                    <label className="p-button-text p-clickable" style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4, cursor: 'pointer'}}>
                                        <i className="pi pi-plus"></i>  selecionar
                                        <FileUploader
                                            hidden
                                            //accept="image/*"
                                            name="arquivo"
                                            randomizeFilename
                                            storageRef={firebase.storage().ref("users")}
                                            onUploadStart={this.handleUploadStart}
                                            onUploadError={this.handleUploadError}
                                            onUploadSuccess={this.handleUploadSuccess}
                                            onProgress={this.handleProgress}
                                        />
                                    </label>
                                : null}
                            </div>

                            <div className="p-col-12 p-md-12 col-lg-12" style={{marginTop: 15 + 'px'}}>
                                <span className="float-label">
                                     
                                    
                                    <div className="p-fileupload-content">
                                        
                                        <div role="progressbar" className="p-progressbar p-component p-progressbar-determinate" aria-valuemin="0" aria-valuemax="100">
                                            {/* <div className="p-progressbar-value p-progressbar-value-animate" style={{display: 'block'}}></div> */}
                                            { (this.state?.isUploading) ? 
                                                <ProgressBar value={this.state?.progress} />
                                                : null }
                                        </div>

                                        <div className="p-fileupload-files">
                                            {(this.state?.fields?.arquivo) ? (
                                                <div className="p-fileupload-row" key={'File0'}>
                                                    <div>
                                                        
                                                        <a alt="arquivo anexado" role="presentation" target="_new" href={this.state?.fields?.arquivo.fullpath} width="50" ><i className="pi pi-camera"></i></a>
                                                    </div>
                                                    <div>
                                                        <a alt="arquivo anexado" role="presentation" target="_new" href={this.state?.fields?.arquivo.fullpath} width="50" >
                                                            {this.state?.fields?.arquivo.name}
                                                        </a>
                                                    </div>
                                                    {/* <div>
                                                        <a alt="arquivo anexado" role="presentation" target="_new" href={this.state?.fields?.arquivo.fullpath} width="50" >
                                                            {this.obtemTamanhoArquivoFormatado(this.state?.fields?.arquivo.bytes)}  
                                                        </a>
                                                    </div> */}
                                                    <div>
                                                        {this.podeEditar() ? <Button key={'File' + '0'} icon="pi pi-times" label="" className="p-button p-component p-button-danger p-button-icon-only" onClick={() => {this.exibirMensagemExclusaoArquivo(0)}}></Button> : null}

                                                        {/* <button type="button" className="p-button p-component p-button-icon-only">
                                                            <span className="pi pi-times p-c p-button-icon-left"></span>
                                                            <span className="p-button-text p-c">X</span>
                                                        </button> */}
                                                    </div>
                                                </div>
                                            ): null}
                                            
                                            
                                        </div>
                                    </div>

                                    

                                </span>
                            </div>

                                                        
                            <div className="p-col-12 p-md-3 col-lg-3" >
                                <img src={this.foto} alt="foto de documento" style={{marginTop: 25 + 'px'}} />
                            </div>
                            <div className="p-col-12 p-md-9 col-lg-9">
                                <h4>Como tirar uma foto sua segurando o documento de identidade</h4>
                                <ul>
                                    <li>Tire uma foto com o celular na horizontal em um local bem iluminado</li>
                                    <li>Selecione a melhor qualidade de imagem do dispositivo para tirar a foto</li>
                                    <li>Segure o documento próximo ao seu rosto, conforme a imagem ao lado</li>
                                    <li>Certifique-se de que o seu rosto e o documento estão nítidos na foto — nada pode estar coberto ou censurado no documento </li>
                                </ul>
                                <p>Se a foto não tiver qualidade aceitável (suficientemente nítida e sem desfoque), pediremos uma nova imagem. </p>
                            </div>
                            


                        </div>
                    </div>

                    <div className="card card-w-title">
                        <h1>Aceite e Envio</h1>


                        <div className="p-grid">

                            <div className="p-col-12 p-md-12" style={{marginTop: 15 + 'px'}} >
                                 <span className="aceite">
                                    <InputSwitch disabled={!this.podeEditar() } id="aceite" name="aceite" checked={this.state?.fields?.aceite} onChange={this.handleChange} />
                                    <label htmlFor="aceite"> Declaro que li e aceito os <a href="./#" onClick={this.exibirContrato}>termos do contrato <i className="pi pi-external-link"></i></a></label>
                                    <Dialog header="Contrato ABF - MODELO V.202004" visible={this.state?.exibirContrato} style={{width: '95vw'}} onHide={() => this.setState({exibirContrato: false})} maximizable blockScroll footer={rodapeContrato}>
                                    
                                        <Contrato ref={el => (this.componentRef = el)} />

                                    </Dialog>
                                 </span>
                            </div>       
                            <div className="p-col-12 p-md-3" style={{marginTop: 15 + 'px'}} >
                            </div>                             
                            <div className="p-col-12 p-md-3" style={{marginTop: 15 + 'px'}} >
                                {this.podeEditar() && this.cpfCorretor ? <Button label="Salvar sem Enviar" type="button" onClick={this.saveForm} icon="pi pi-check"> </Button> : null}
                            </div>
                            <div className="p-col-12 p-md-3" style={{marginTop: 15 + 'px'}} >
                                {this.podeEditar() && !this.cpfCorretor ? <Button label="Salvar e Enviar" type="button" onClick={this.submitForm}  disabled={!this.state?.fields?.aceite}  icon="pi pi-cloud-upload"  ></Button> : null}
                            </div>
                            <div className="p-col-12 p-md-3" style={{marginTop: 15 + 'px'}} >
                                {this.podeEditar() ? <Button label="Descartar esta Proposta" type="button" icon="pi pi-trash" className="p-button-danger" onClick={this.zeraState}></Button> : null}
                            </div>
                            {!this.podeEditar() ?
                                <div className="p-col-12 p-md-12" >
                                    <div aria-live="polite" className="p-message p-component p-message-error" role="alert">
                                        <span className="p-message-icon pi pi-fw pi-exclamation-triangle"></span>
                                        <span className="p-message-text">Esta proposta já foi enviada e não pode mais ser editada.</span>
                                    </div>
                                </div>
                            : null }
                        </div>
                    </div>
                  {/* </form> */}
                </div>
            </div>
        );
    }
}