import React, {Component}  from 'react';
import './Contrato.css';

export class Contrato extends Component {
  constructor() {
    super();
  }

  render() {
    return (
        <div className='divContratoToPrint' style={{fontSize: 10 + 'pt', backgroundImage: 'url(../assets/layout/images/modelo.png)', backgroundRepeat: 'repeat'}}>

            <p><b><span>CONTRATO
            DE PRESTAÇÃO DE SERVIÇOS E BENFÍCIOS – PESSOA FÍSICA</span></b></p>

            <p><b><span>Contrato
            Registrado no 4º Ofício de Títulos e Documentos</span></b></p>



            <p><b><span>DAS
            PARTES – São partes no presente contrato: ABF – ASSISTÊNCIA BRASILEIRA À
            FAMILIA, pessoa jurídica inscrita no CNPJ sob o nº 00.863.663/0001-30, com sede
            no endereço da Av. Rio Branco, 109 sala 602, Centro, Rio de Janeiro – RJ,
            doravante denominada CONTRATADA, neste ato representada pelo seu representante
            legal e de outro lado o CONTRATANTE, qualificado na proposta anexa, tem entre
            si justo e contratado o seguinte:</span></b></p>



            <p><b><span>CLÁUSULA
            1ª – OBJETO</span></b></p>

            <p><b><span>1.1 –</span></b><span> O objeto deste contrato é a prestação
            de serviços funerários na ocorrência de óbito do titular e/ou beneficiário(s)
            nele inscritos indicados pelo CONTRATANTE, de acordo com as cláusulas e
            condições aqui previstas.</span></p>

            <p><b><span>1.2 –</span></b><span> Termos aditivos, anexos e recibos são
            considerados documentos integrantes deste contrato, uma vez validados por ambas
            as partes.</span></p>



            <p><b><span>CLÁUSULA 2ª – IDADE LIMITE
            PARA COBERTURA – </span></b><span>Tanto
            o titular, quanto o(s) beneficiário(s) terão direito à cobertura dos serviços
            contratados, respeitando o(s) período(s) de carência(s) de que trata(m) a
            cláusula 9ª, conforme plano e categoria escolhidos, não havendo limite de
            idade.</span></p>



            <p><b><span>CLÁUSULA 3ª – COBERTURA
            DESTE CONTRATO – </span></b><span>A
            cobertura deste contrato garante a prestação de serviços e benefícios conforme
            discriminado abaixo e de acordo com o tipo de plano e categoria escolhidos.</span></p>



            <p><b><span>3.1 – ASSISTÊNCIA AO
            FUNERAL </span></b></p>



            <p><b><span>3.1.1 –</span></b><span> A CONTRATADA disponibilizará um agente
            credenciado da funerária local, que tomará as providências necessárias e
            possíveis para a realização do funeral. Quando necessário, uma pessoa da
            família deverá acompanhar o agente para que ele possa realizar sua prestação de
            serviço.</span></p>



            <p><b><span>3.1.2 – SEPULTAMENTO – </span></b><span>Fornecimento de serviços e pagamento das
            despesas dos seguintes itens necessários ao funeral:</span></p>



            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Urna
            funerária <b>(de acordo com a categoria do
            plano escolhido – vide 4.2);</b></span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Local
            para o velório (capela sem ar condicionado) – <b>de acordo com a lei vigente</b>;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Ornamentação
            do corpo com flores naturais da estação;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Uma
            coroa de flores com dizeres redigidos pela família;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Carro
            funerário para remoção do corpo;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Plantão
            24 horas para atendimento telefônico;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Despachante
            autorizado, apto para tomar, em nome da família, todas as providências
            necessárias para o funeral;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Remoção
            do corpo para o cemitério onde será realizado o funeral;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Locação
            de espaço para sepultamento (gaveta ou carneiro) - caso o titular e/ou
            beneficiário(s) não disponha(m) de jazigo, poderá(<span>ão</span>)
            ocupar temporariamente em cemitério público do tipo tradicional indicado pela
            família. Se o cemitério escolhido não dispuser naquele momento de espaço tipo
            gaveta ou carneiro, a família escolherá outro cemitério que disponha do espaço
            tipo gaveta ou carneiro ou a realização do sepultamento naquele escolhido,
            mesmo que o sepultamento se dê em <b>padrão
            inferior</b> <b>tipo “cova rasa” - chão</b>.
            O período de locação do espaço para sepultamento previsto neste contrato <b>se limitará ao prazo de 03 (três) anos</b>,
            após o qual será de inteira responsabilidade da família; </span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Registro
            do óbito em cartório – desde que o cartório permita que o registro seja feito
            pelo agente, mas sempre com a presença obrigatória de um familiar do falecido.
            As informações contidas na Certidão de Óbito, bem como a sua conferência, são
            de inteira responsabilidade do familiar;</span></p>

            <p><span>Obs.: De acordo com o município de
            sepultamento e visando atender e/ou agilizar o sepultamento, o familiar poderá
            registrar o óbito sem a presença do agente da funerária;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Pagamento
            das taxas municipais e cemiteriais relativas ao funeral;</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><span>Coche
            (transporte interno); e</span></p>

            <p><span><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span></span><b><span>CONTRATAÇÃO OPCIONAL - CREMAÇÃO</span></b><span> - <b><span> </span>vide cláusula 5ª</b>.</span></p>



            <p><b><span>3.1.2.1
            – </span></b><span>A obtenção da
            Declaração de Óbito e sua entrega ao despachante designado pela CONTRATADA é de
            exclusiva responsabilidade da família.</span></p>

            <p><b><span>3.1.2.2</span></b><span> – As taxas anuais cobradas pelo
            cemitério, pertinentes à manutenção do espaço locado, serão de inteira
            responsabilidade da família.</span></p>

            <p><b><span>3.1.3 – REMOÇÃO</span></b><span>
            –&nbsp;A CONTRATADA se obriga a promover apenas a primeira remoção do corpo até
            o local do funeral, desde que este seja realizado no mesmo município onde
            ocorreu o falecimento.</span><span></span></p>

            <p><b><span>3.1.3.1</span></b><span> – Quando o
            falecimento ocorrer fora do município de moradia indicado neste instrumento,
            mas dentro do Estado do Rio de Janeiro, este contrato garante a remoção do
            corpo para a realização do funeral no município de moradia habitual, desde que
            estes municípios estejam ligados por fronteiras de limite.</span><span></span></p>

            <p><b><span>3.1.3.2</span></b><span> – Quando o falecimento
            ocorrer fora do Estado do Rio de Janeiro e em município diverso daquele que
            está indicado neste contrato como sendo o de moradia habitual, este instrumento
            garante a remoção do corpo para a realização do funeral no referido município,
            desde que, o traslado <b>não ultrapasse a
            100 (cem) km – considerando ida e volta -</b> entre o local de falecimento e
            local do sepultamento. </span><span></span></p>

            <p><b><span>3.1.3.3</span></b><span> – Quando a família
            desejar o sepultamento em município diverso daquele em que se verificou o
            falecimento, bem como diverso do que está declarado neste contrato como sendo o
            de moradia habitual, haverá uma diferença sobre o valor da remoção e cobrança
            das taxas de mudança de município, de responsabilidade da família, que deverá
            ser paga diretamente à agência funerária designada para realizar o serviço. A
            partir da chegada do corpo ao município em que se dará o sepultamento, os
            serviços garantidos por este contrato serão prestados respeitando as cláusulas
            contratuais específicas que tratam da remoção.</span><span></span></p>

            <p><b><span>3.1.3.4</span></b><span> – A segunda remoção,
            quando solicitada pela família, não será custeada pela CONTRATADA, cabendo ao
            solicitante o pagamento das despesas decorrentes do traslado diretamente à
            agência funerária responsável.</span><span></span></p>



            <p><b><span>CLÁUSULA
            4ª – TIPOS DE PLANO E CATEGORIAS</span></b></p>

            <p><b><span>4.1
            – Tipos: 4.1.1</span></b><span> –
            Individual e <b>4.1.2</b> – Familiar:
            titular e cônjuge, companheira(o), agregado(s) e filho(s).</span></p>



            <p><b><span>4.2
            – Categorias:</span></b></p>

            <p><b><span>4.2.1
            – Rubi: Urna funerária sem visor, envernizada, padrão simples;</span></b></p>

            <p><b><span>4.2.2
            – Safira: Urna funerária com visor, envernizada, padrão especial;</span></b><span></span></p>



            <p><b><span>CLÁUSULA 5ª – CREMAÇÃO</span></b><span> – Cabe ao titular e respectivo (s)
            beneficiário(s), a prévia assinatura com reconhecimento de firma da competente
            autorização para a cremação, conforme a lei exige, a qual, juntamente com a
            Declaração do Óbito assinada por dois médicos ou por um médico legista, deverá
            ser entregue ao despachante designado pela CONTRATADA, para proceder ao
            registro do óbito. Este serviço será realizado caso haja crematório no
            município de moradia habitual, tendo direito, quando necessário, a utilização
            da geladeira do crematório por 24 (vinte e quatro) horas. Nos casos de morte
            violenta será exigida ainda autorização judiciária para que se possa proceder à
            cremação (lei nº 6.015, de 31/12/73).</span></p>

            <p><b><span>5.1 – A CONTRATADA não se
            responsabiliza pelos custos relativos à documentação necessária para
            cremação.<span>  </span>Na hipótese de não ser dada
            autorização para a realização da cremação, a CONTRATADA não se responsabiliza
            pela devolução da importância paga para a mesma. </span></b></p>

            <p><span>5.2
            – A contratação da CREMAÇÃO é opcional, mas sendo OBRIGATÓRIA para o(s)
            beneficiário(s) indicado(s) no contrato, conforme tabela de mensalidade
            vigente, em quaisquer dos planos indicados na cláusula 4ª.</span></p>

            <p><b><span>5.3 – O beneficiário
            (titular ou dependente)</span></b><span>
            <b>que, na data do óbito, não tenha
            cumprido o período de carência de 365 (trezentos e sessenta e cinco) dias não
            terá direito à cobertura para a cremação.</b></span></p>



            <p><b><span>CLÁUSULA 6ª – LIMITE DAS
            DESPESAS E BENEFÍCIOS –</span></b><span>
            Quando houver excedentes nos serviços e benefícios do limite estabelecido em
            cada plano, causado por despesas extras, a responsabilidade pelo pagamento
            deste excesso será integralmente da família.</span></p>

            <p><b><span>6.1 –</span></b><span> Caso o falecimento do beneficiário
            ocorra fora do Estado do Rio de Janeiro, a CONTRATADA garantirá a prestação dos
            serviços e benefícios no local do falecimento, de acordo com o plano
            contratado, limitando aos valores vigentes conforme tabela disponível na sede
            da CONTRATADA.</span></p>

            <p><b><span>6.2</span></b><span> – Quando os serviços e benefícios não
            atingirem o limite das despesas, em NENHUMA HIPÓTESE HAVERÁ SALDO A RESTITUIR.</span></p>



            <p><b><span>CLÁUSULA 7ª – ÂMBITO
            TERRITORIAL DA COBERTURA – </span></b><span>A
            cobertura deste contrato garante os serviços oferecidos na cláusula 3.1, sua
            realização e pagamento dos custos, exclusivamente no território nacional e no
            município de moradia habitual do falecido. Quando, por motivo de trabalho, o
            falecido tinha habitação em alojamento da empresa ou em moradia no município em
            que trabalhava, este município, para os fins deste contrato, será considerado
            como de moradia habitual do falecido. <b>Quando
            o falecimento ocorrer no município de residência e a família desejar que o
            sepultamento seja feito em outro município, as providências, os custos do
            traslado do corpo e as taxas de mudança de município serão de exclusiva
            responsabilidade da família</b>. A partir da chegada do corpo ao município em
            que se dará o sepultamento, os serviços funerais garantidos por este contrato
            serão prestados normalmente.</span></p>



            <p><b><span>CLÁUSULA 8ª – VIGÊNCIA
            DESTE CONTRATO</span></b><span> – Este
            contrato terá um prazo de 12 (doze) meses, a partir da data da aceitação da
            proposta de admissão, sendo renovado automaticamente por período de 12 (doze)
            meses se não houver comunicação por escrito em sentido contrário por quaisquer
            das partes contratantes até 30 (trinta) dias antes do seu vencimento.</span></p>



            <p><b><span>CLÁUSULA 9ª – CARÊNCIA – </span></b><span>Todos os beneficiários (titular e/ou
            dependente) incluídos neste contrato sofrerão carência de acordo com a faixa
            etária correspondente: até 60 (sessenta) anos – 90 (noventa) dias; de 61
            (sessenta e um) a 70 (setenta) anos – 180 (cento e oitenta) dias; e a partir de
            71 (setenta e um) anos – 300 (trezentos) dias.</span></p>

            <p><b><span>O enquadramento da
            carência respeitará a idade do beneficiário (titular e/ou dependente) na data
            do primeiro vencimento.</span></b></p>

            <p><b><span>A carência da CREMAÇÃO
            será de 365 (trezentos e sessenta e cinco) dias independentemente da faixa
            etária.</span></b></p>

            <p><b><span>O período será contado a
            partir da primeira parcela paga através de boleto bancário ou na sede da
            CONTRATADA.</span></b></p>

            <p><b><span>9.1 – Antecipações de
            pagamentos não serão consideradas para efeito de cálculo do prazo de carência.</span></b><span> Quando da renovação o titular e seus
            beneficiários não cumprirão novas carências.<span> 
            </span>Se o titular e seus respectivos beneficiários decidirem optar por uma
            categoria superior, ocorrerá nova carência de acordo com a sua faixa etária
            para disponibilidade das novas condições, prevalecendo as condições anteriores,
            caso ocorra o óbito neste período.</span></p>

            <p><b><span>9.2 – </span></b><span>Os filhos nascidos no período de
            vigência do contrato, desde que incluídos como beneficiários até 30 (trinta)
            dias após o seu nascimento, não estarão sujeitos aos prazos de carências
            previstos acima. Quando da inclusão, o CONTRATANTE pagará a mensalidade vigente
            relativa ao beneficiário então incluído.</span></p>



            <p><b><span>CLÁUSULA 10ª – PAGAMENTOS</span></b><span> – Além da taxa de inscrição, o titular
            e os respectivos beneficiários deverão pagar a mensalidade, de acordo com a
            faixa etária correspondente, acrescida dos opcionais contratados, se houver,
            que serão cobrados ambos em sistema de pré-pagamento. <b>As mensalidades serão devidas a partir do mês seguinte a assinatura da
            proposta ou, por solicitação do beneficiário titular, no mês subsequente.</b></span></p>

            <p><b><span>O dia do vencimento dos boletos será de escolha do CONTRATANTE podendo ser 05, 10, 15, 20, 25 ou 30</span></b><span>. Todos os pagamentos deverão ser
            efetuados através de boleto bancário, exceto o pagamento da taxa de inscrição,
            que deverá ser feito diretamente ao vendedor.</span></p>

            <p><span>A CONTRATADA não adota cobrança domiciliar, não sendo, portanto, reconhecida
            qualquer autenticação que não seja a bancária mecanizada.<b></b></span></p>

            <p><b><span>10.1 – O carnê de
            pagamento será encaminhado através de e-mail de cadastro. Na hipótese de o
            CONTRATANTE não receber o carnê de pagamento até a data do vencimento, deverá
            contatar a CONTRATADA para o seu recebimento.</span></b></p>

            <p><b><span>10.2 – Nenhum recibo
            quitará obrigações anteriores, mas tão somente a obrigação nele contemplada.</span></b></p>

            <p><b><span>10.3 – O CONTRATANTE pode
            optar pela forma de envio dos carnês de pagamentos e quaisquer outros
            comunicados pertinentes ao contrato.</span></b></p>



            <p><b><span>CLÁUSULA 11ª – REAJUSTE DA
            MENSALIDADE</span></b><span></span></p>

            <p><b><span>11.1 – </span></b><span>A periodicidade dos reajustes das
            mensalidades é anual até que a lei permita um período menor quando então será
            automaticamente e de forma imediatamente aplicado o menor prazo legalmente
            permitido.<span>   </span>Os períodos serão contados a
            partir da data de assinatura da proposta de admissão. O índice de reajuste será
            a variação do IGPM (Índice Geral de Preços do Mercado), ou em sua falta, pelo maior
            índice legal vigente a época e praticado pelo mercado. </span></p>

            <p><b><span>11.2 – Faixa Etária – </span></b><span>Ocorrendo alterações na idade de
            qualquer beneficiário – (titular e/ou dependente) – que importe deslocamento
            para outra faixa etária, o valor da mensalidade será reajustado para o valor da
            nova faixa, no mês seguinte ao da ocorrência.<span> 
            </span>As faixas etárias previstas neste contrato são: de 0 (zero) a 10 (dez)
            anos; de 11 (onze) a 17 (dezessete) anos; de 18 (dezoito) a 40 (quarenta) anos;
            de 41 (quarenta e um) a 50 (cinquenta) anos; de 51 (cinquenta e um) a 60
            (sessenta) anos; de 61 (sessenta e um) a 65 (sessenta e cinco) anos; de 66
            (sessenta e seis) a 70 (setenta) anos; de 71 (setenta e um) a 75 (setenta e
            cinco) anos; de 76 (setenta e seis) a 79 (setenta e nove) anos; de 80 (oitenta)
            a 89 (oitenta e nove) anos; e a partir de 90 (noventa) anos.</span></p>



            <p><b><span>CLÁUSULA 12ª –
            INADIMPLÊNCIA</span></b></p>

            <p><b><span>12.1 – O não pagamento das mensalidades até a data do seu
            vencimento acarretará a suspensão dos serviços e benefícios, portanto, em caso
            de falecimento de um ou mais beneficiários, não será autorizada a execução dos
            funerais. </span></b></p>

            <p><b><span>12.2 –</span></b><span> A(s) mensalidade(s) paga(s) após o vencimento sofrerá(<span
            >ão</span>) acréscimo de multa no equivalente a 2% (dois por
            cento), juros de mora de 1% (um por cento) ao mês e correção monetária.</span></p>

            <p><b><span>12.3 –</span></b><span> <b>O atraso superior a
            60 (sessenta) dias importará no cancelamento imediato deste contrato sem prévio
            aviso, ainda que haja a efetivação do pagamento das parcelas mencionadas no
            item anterior após o 60º (sexagésimo) dia de atraso, não impedirá o cancelamento
            imediato deste contrato, também independente de notificação prévia.</b></span></p>

            <p><b><span>12.4 –</span></b><span> Em razão do cancelamento do contrato estarão suspensos os
            direitos e obrigações das partes, bem como os serviços e benefícios, não sendo
            autorizada a execução do objeto deste.</span></p>

            <p><b><span>12.5 –</span></b><span> Será aplicada a carência para cobertura dos serviços no
            período de 02 (dois) dias úteis após a regularização do pagamento inferior a 2
            (duas) mensalidades atrasadas.</span></p>



            <p><b><span>CLÁUSULA 13ª –
            IDENTIFICAÇÃO DO BENEFICIÁRIO – </span></b><span>A
            CONTRATADA enviará uma carteira de identificação para o titular e para o(s)
            beneficiário(s) deste contrato, especificando o tipo e categoria escolhidos,
            além da cópia da ficha-proposta e do respectivo contrato.</span></p>



            <p><b><span>CLÁUSULA 14ª – ALTERAÇÕES
            DE PLANOS - </span></b><span>Quaisquer
            alterações no plano contratado, (tipos e/ou categorias) somente poderão ser
            realizadas nos escritórios da CONTRATADA, o que poderá acarretar a obrigação de
            cumprimento de novos prazos de carência e eventual pagamento de diferença de
            valores em caso de majoração dos mesmos, respeitando a data de opção de troca
            de plano para apuração de carência e diferença de valores.</span></p>



            <p><b><span>CLÁUSULA 15ª – EXCLUSÕES E
            INCLUSÕES - </span></b><span>O titular
            poderá incluir novos beneficiários, <b>desde
            que analisado e aprovado pela CONTRATADA</b>, caso em que passaria a
            mensalidade do beneficiário com o valor vigente, ou excluir, mediante
            comunicação expressa prévia de 30 (trinta) dias, aqueles anteriormente
            indicados, cessando, assim, a obrigação de pagamento das mensalidades do
            beneficiário excluído, bem como o direito a prestação dos serviços e benefícios
            contratados pelo titular. </span></p>

            <p><b><span>15.1 –</span></b><span> No caso de novas inclusões
            aplicar-se-ão sempre os prazos de carência previstos para os novos
            beneficiários. As inclusões e exclusões somente poderão ser feitas pelo titular
            e nos escritórios da CONTRATADA<b>.</b></span></p>

            <p><b><span>15.2 –</span></b><span> As mensalidades dos beneficiários
            incluídos após a assinatura deste contrato respeitarão a data de reajuste
            aplicada ao titular por força da data da contratação inicial.</span></p>



            <p><b><span>CLÁUSULA 16ª – SOLICITAÇÃO
            DOS SERVIÇOS DE ASSISTÊNCIA FUNERAL – </span></b><span>Quando
            da ocorrência do óbito de quaisquer beneficiários deste contrato, o responsável
            deverá entrar em contato com a Central 24 horas de Atendimento da CONTRATADA
            através do número constante da carteira de identificação do falecido,
            oportunidade em que deverá providenciar, também, a Declaração de Óbito -
            documento indispensável para remoção do corpo - quando, então, será dado
            prosseguimento ao serviço de atendimento para realização do funeral.</span></p>

            <p><b><span>16.1 – Em nenhuma hipótese
            os beneficiários poderão contratar diretamente os serviços de agências
            funerárias, hipótese que desobriga a CONTRATADA da prestação dos serviços e do
            pagamento e/ou ressarcimento de quaisquer ônus oriundo do funeral contratado
            por meio de terceiros.</span></b></p>

            <p><b><span>CLÁUSULA 17ª – LIMITAÇÕES
            E EXCLUSÕES DESTE CONTRATO – </span></b><span>Caso
            os familiares do falecido não cooperem, quando for necessário, para que o
            prestador de serviços garantido por este contrato possa desempenhar plenamente
            atividades contratadas, poderá haver limitação dos serviços, inclusive, quanto
            às questões legais e burocráticas que necessitem imperiosamente de assinaturas,
            reconhecimento de firmas e expedição de documentos legais os quais estejam os
            familiares obrigados a providenciarem.</span></p>



            <p><b><span>17.1 – ESTÃO EXCLUÍDOS DA
            COBERTURA DESTE CONTRATO:</span></b></p>



            <p><b><span>A) OS ÓBITOS DECORRENTES DE: FENÔMENOS
            FORTUITOS DA NATUREZA (INUNDAÇÃO, FURACÃO, ERUPÇÃO VULCÂNICA, TERREMOTOS,
            ETC.), SITUAÇÕES DE GUERRA, COMOÇÕES SOCIAIS, TERRORISMO, IRRADIAÇÃO NUCLEAR,
            PRÁTICA DE ATOS ILEGAIS, SUICÍDIO, USO DE DROGAS, ENTORPECENTES OU ÁLCOOL;</span></b></p>

            <p><b><span>B) DESPESAS COM A EXUMAÇÃO
            DOS CORPOS QUE ESTIVEREM NO JAZIGO DA FAMÍLIA, QUANDO DO SEPULTAMENTO, ASSIM
            COMO RECIPIENTE PARA COLOCAÇÃO DE OSSOS;</span></b></p>

            <p><b><span>C) TRASLADO DO CORPO PARA
            QUE A CREMAÇÃO SEJA FEITA EM OUTRO MUNICÍPIO QUE NÃO SEJA AQUELE ELEITO PELO
            CONTRATO, QUANDO NÃO HOUVER ESTE SERVIÇO NO MUNICÍPIO DA MORADIA HABITUAL;</span></b></p>

            <p><b><span>D) DESPESAS DE CARTÓRIO
            REFERENTES À AUTORIZAÇÃO DOS FAMILIARES PARA A REALIZAÇÃO DA CREMAÇÃO;</span></b></p>

            <p><b><span>E) OS CUSTOS DE AQUISIÇÃO,
            CONSTRUÇÃO, BENFEITORIAS, MANUTENÇÃO E/OU RECUPERAÇÃO DE JAZIGOS;</span></b></p>

            <p><b><span>F) TANATOPRAXIA (TÉCNICA
            DE EMBALSAMAMENTO);</span></b></p>

            <p><b><span>G) REMOÇÃO - VIDE CLÁUSULAS 3.1.3, 3.1.3.1, 3.1.3.2,
            3.1.3.3 E 3.1.3.4;</span></b></p>

            <p><b><span>H) A PRESTAÇÃO DOS
            SERVIÇOS NOS MUNICÍPIOS EM QUE A LEGISLAÇÃO NÃO PERMITA QUE A CONTRATADA E SEUS
            PRESTADORES DE SERVIÇOS INTERVENHAM PARA A REALIZAÇÃO DO FUNERAL;</span></b></p>

            <p><b><span>I) A DESPESA PRÉVIA DE
            EXUMAÇÃO PARA SEPULTAMENTO EM JAZIGO LOCADO, ASSIM COMO RECIPIENTE PARA
            COLOCAÇÃO DE OSSOS;</span></b></p>

            <p><b><span>J) RETIRADA DE ÓRTESES
            E/OU PRÓTESES PARA SEPULTAMENTO E/OU CREMAÇÃO; e</span></b></p>

            <p><b><span>K) PAGAMENTOS REFERENTES
            AO USO DO AR CONDICIONADO NA CAPELA DURANTE O VELÓRIO.</span></b></p>



            <p><b><span>CLÁUSULA 18ª – ACEITAÇÃO
            DA PROPOSTA QUE ANTEVEM AO CONTRATO – </span></b><span>A
            CONTRATADA terá o prazo de 30 (trinta) dias, contados a partir da data do
            recebimento da mesma, para análise técnica de aceitação da proposta. Caso a
            proposta não seja aceita por motivos técnicos, os valores dados em pagamento
            serão integralmente devolvidos atualizados, e no prazo máximo de 30 (trinta)
            dias.<b> </b></span></p>



            <p><b><span>CLÁUSULA 19ª – COMUNICAÇÃO
            SOBRE DOENÇAS PREEXISTENTES EM FASE TERMINAL - </span></b><span>O titular e seus respectivos
            beneficiários, no ato da inclusão no contrato, ficam obrigados a comunicar à
            CONTRATADA quaisquer doenças preexistentes em fase terminal, sob pena de não
            serem realizados os serviços abrangidos por este contrato.</span></p>

            <p><b><span>19.1</span></b><span> – A preexistência de doenças impede a
            inclusão do portador no contrato e deve ser declarada quando do preenchimento
            da proposta sob pena de responder civilmente por omissão de informação
            importante, a qual é pré-requisito para a formalização deste contrato.</span></p>

            <p><b><span>19.2</span></b><span> – A omissão de que trata o item
            anterior também importará na perda do direito aos serviços de assistência ao
            funeral, quando o falecimento do beneficiário ocorrer em consequência de doença
            preexistente. </span></p>



            <p><b><span>CLÁUSULA 20ª – CERTIDÃO DE
            ÓBITO - </span></b><span>A Certidão de
            Óbito deverá ser entregue pela CONTRATADA ou seus prepostos à família no
            decorrer do serviço funeral.<b></b></span></p>

            <p><b><span>20.1 –</span></b><span> Nos municípios onde os prestadores de
            serviços não puderem atuar, a responsabilidade pelo registro em cartório será
            atribuição dos familiares.<b></b></span></p>



            <p><b><span>CLÁUSULA 21ª – FALECIMENTO
            DO TITULAR - </span></b><span>Em caso de
            falecimento do titular, o(s) beneficiário(s) por ele indicado (s) poderá(<span
            >ão</span>) assinar novo contrato com aproveitamento do tempo de
            carências do contrato anterior e com a tabela de mensalidade vigente, desde
            que, compareçam no escritório da contratada, no prazo máximo de 10 dias após o
            falecimento.</span></p>



            <p><b><span>CLÁUSULA 22ª – DAS
            DISPOSIÇÕES GERAIS – Fica autorizada a inclusão de todos os beneficiários
            declarados na proposta, ainda que posteriormente, em seguro ou reembolso
            eventualmente firmado pela CONTRATADA, a seu critério, instituindo-se a
            CONTRATADA como beneficiária.</span></b></p>

            <p><b><span>22.1 – FICA O TITULAR E
            BENEFICIÁRIO(S) OBRIGADOS A MANTEREM CADASTRO COM AS INFORMAÇÕES PESSOAIS
            ATUALIZADAS JUNTO AO CONTRATO, SOB PENA DE SEREM INTERPRETADAS DE FORMA
            EQUIVOCADA AS INFORMAÇÕES DE MORADIA PARA REALIZAÇÃO DOS SERVIÇOS FUNERÁRIOS.</span></b></p>

            <p><b><span>22.2 – Fará parte
            integrante deste contrato quaisquer adendos que se fizerem necessários por
            força de novas circunstâncias.</span></b></p>

            <p><b><span>A CONTRATADA não se
            responsabiliza por qualquer compromisso que não faça parte deste instrumento.</span></b><span></span></p>



            <p><span>Fica
            estabelecido o foro da cidade do Rio de Janeiro para dirimir qualquer
            controvérsia oriunda deste contrato.</span></p>

        </div>
      );

    }
  }

export default Contrato
