//import React from 'react';
import axios from 'axios';
//import { QRCode } from 'react-qrcode-logo';
//import { PropostaModel } from '../models/PropostaModel';

//const proposta = new PropostaModel();


export class PropostaService {
    
    constructor() {
        //this.proposta = new PropostaModel();
        this.util = {
            planos: [
                {label: 'Individual', value: 'I'}, 
                {label: 'Familiar', value: 'F'}
            ],
            categorias : [
                {label: 'Rubi',value: 'RUBI'}, 
                {label: 'Safira', value: 'SAFIRA'},
                {label: 'Ouro', value: 'OURO'},
                // {label: 'Diamante', value: 'DIAMANTE'},
            ],
            diasDePagamentos : [
                {label: '5', value: '5'}, 
                {label: '10', value: '10'}, 
                {label: '15', value: '15'}, 
                {label: '20', value: '20'}, 
                {label: '25', value: '25'}, 
                {label: '30', value: '30'}
            ],
            sexos : [
                {label: 'Masculino', value: 'MASCULINO'}, 
                {label: 'Feminino', value: 'FEMINIMO'}, 
                {label: 'Não Informado', value: 'NI'}
            ],
            estadosCivis : [
                {label: 'Solteiro', value: 'SOLTEIRO'}, 
                {label: 'Casado', value: 'CASADO'}, 
                {label: 'Divorciado', value: 'DIVORCIADO'}, 
                {label: 'Separado', value: 'SEPARADO'}, 
                {label: 'Viuvo', value: 'VIUVO'}, 
                {label: 'Outros', value: 'OUTROS'}
            ],
            parentescos : [
                {label: 'Conjuge', value: 'CONJUGE'}, 
                {label: 'Filho', value: 'FILHO'}, 
                {label: 'Agregado', value: 'AGREGADO'}, 
                {label: 'Outros', value: 'OUTROS'}
            ],
            tiposDePagamentos : [
                {label: 'Mensal', value: 'MENSAL'}, 
                // {label: 'Trimestral', value: 'TRIMESTRAL'}, 
                // {label: 'Semestral', value: 'SEMESTRAL'}, 
                // {label: 'Anual', value: 'ANUAL'}
            ],
            tabela: [
                
                // TABELA RETIRADA EM 09/05/21
                // {codigo: 'R00-10', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   3.30, valor3vidasoumais:   2.50, fatorcremacao: 1.79},
                // {codigo: 'R11-17', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   6.60, valor3vidasoumais:   5.00, fatorcremacao: 1.82},
                // {codigo: 'R18-40', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 18, idadefinal: 40,   valor1vida:  23.00, valor2vidas:  15.50, valor3vidasoumais:  11.00, fatorcremacao: 1.54},
                // {codigo: 'R41-50', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 41, idadefinal: 50,   valor1vida:  27.50, valor2vidas:  18.50, valor3vidasoumais:  14.00, fatorcremacao: 1.71},
                // {codigo: 'R51-60', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 51, idadefinal: 60,   valor1vida:  33.50, valor2vidas:  25.15, valor3vidasoumais:  22.00, fatorcremacao: 1.76},
                // {codigo: 'R61-65', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 61, idadefinal: 65,   valor1vida:  45.00, valor2vidas:  36.00, valor3vidasoumais:  32.00, fatorcremacao: 1.69},
                // {codigo: 'R66-70', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 66, idadefinal: 70,   valor1vida:  65.00, valor2vidas:  52.00, valor3vidasoumais:  48.00, fatorcremacao: 1.78},
                // {codigo: 'R71-75', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 71, idadefinal: 75,   valor1vida:  90.00, valor2vidas:  78.50, valor3vidasoumais:  72.00, fatorcremacao: 1.77},
                // {codigo: 'R76-79', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 76, idadefinal: 79,   valor1vida: 125.00, valor2vidas: 118.75, valor3vidasoumais: 105.00, fatorcremacao: 1.76},
                // {codigo: 'R80-89', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 80, idadefinal: 89,   valor1vida: 185.00, valor2vidas: 175.75, valor3vidasoumais: 160.00, fatorcremacao: 1.82},
                // {codigo: 'R90-00', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 90, idadefinal: 1000, valor1vida: 205.00, valor2vidas: 195.75, valor3vidasoumais: 180.00, fatorcremacao: 1.84}, 
                
                // {codigo: 'S00-10', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   4.15, valor3vidasoumais:   3.50, fatorcremacao: 1.91},
                // {codigo: 'S11-17', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   7.90, valor3vidasoumais:   7.00, fatorcremacao: 1.74},
                // {codigo: 'S18-40', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 18, idadefinal: 40,   valor1vida:  26.50, valor2vidas:  17.50, valor3vidasoumais:  13.00, fatorcremacao: 1.64},
                // {codigo: 'S41-50', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 41, idadefinal: 50,   valor1vida:  31.75, valor2vidas:  21.50, valor3vidasoumais:  18.00, fatorcremacao: 1.73},
                // {codigo: 'S51-60', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 51, idadefinal: 60,   valor1vida:  38.75, valor2vidas:  29.00, valor3vidasoumais:  25.00, fatorcremacao: 1.76},
                // {codigo: 'S61-65', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 61, idadefinal: 65,   valor1vida:  51.50, valor2vidas:  42.00, valor3vidasoumais:  37.50, fatorcremacao: 1.73},
                // {codigo: 'S66-70', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 66, idadefinal: 70,   valor1vida:  74.00, valor2vidas:  62.00, valor3vidasoumais:  56.00, fatorcremacao: 1.74},
                // {codigo: 'S71-75', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 71, idadefinal: 75,   valor1vida:  94.00, valor2vidas:  83.00, valor3vidasoumais:  75.00, fatorcremacao: 1.83},
                // {codigo: 'S76-79', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 76, idadefinal: 79,   valor1vida: 135.00, valor2vidas: 120.00, valor3vidasoumais: 110.00, fatorcremacao: 1.82},
                // {codigo: 'S80-89', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 80, idadefinal: 89,   valor1vida: 200.00, valor2vidas: 195.00, valor3vidasoumais: 175.00, fatorcremacao: 1.79},
                // {codigo: 'S90-00', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 90, idadefinal: 1000, valor1vida: 220.00, valor2vidas: 215.00, valor3vidasoumais: 200.00, fatorcremacao: 1.81},

                //TABELA RETIRADA EM 13/06/2021
                // {codigo: 'R00-10', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   2.90, valor3vidasoumais:   2.40, fatorcremacao: 1.621, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R11-17', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   5.70, valor3vidasoumais:   4.80, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R18-40', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 18, idadefinal: 40,   valor1vida:  19.90, valor2vidas:  13.70, valor3vidasoumais:  11.50, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R41-50', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 41, idadefinal: 50,   valor1vida:  23.70, valor2vidas:  16.40, valor3vidasoumais:  13.70, fatorcremacao: 1.646, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R51-60', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 51, idadefinal: 60,   valor1vida:  29.00, valor2vidas:  23.20, valor3vidasoumais:  21.20, fatorcremacao: 1.647, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R61-65', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 61, idadefinal: 65,   valor1vida:  38.90, valor2vidas:  31.10, valor3vidasoumais:  28.50, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R66-70', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 66, idadefinal: 70,   valor1vida:  56.20, valor2vidas:  50.60, valor3vidasoumais:  46.50, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R71-75', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 71, idadefinal: 75,   valor1vida:  76.60, valor2vidas:  68.90, valor3vidasoumais:  63.40, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R76-79', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 76, idadefinal: 79,   valor1vida: 101.40, valor2vidas:  98.40, valor3vidasoumais:  95.40, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R80-89', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 80, idadefinal: 89,   valor1vida: 154.20, valor2vidas: 151.10, valor3vidasoumais: 145.00, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R90-00', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 90, idadefinal: 1000, valor1vida: 168.10, valor2vidas: 164.70, valor3vidasoumais: 158.20, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99}, 
                
                // {codigo: 'S00-10', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   3.50, valor3vidasoumais:   2.90, fatorcremacao: 1.629, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S11-17', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   6.90, valor3vidasoumais:   5.80, fatorcremacao: 1.638, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S18-40', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 18, idadefinal: 40,   valor1vida:  23.80, valor2vidas:  16.40, valor3vidasoumais:  13.80, fatorcremacao: 1.646, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S41-50', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 41, idadefinal: 50,   valor1vida:  28.50, valor2vidas:  19.60, valor3vidasoumais:  16.50, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S51-60', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 51, idadefinal: 60,   valor1vida:  34.80, valor2vidas:  27.80, valor3vidasoumais:  25.40, fatorcremacao: 1.647, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S61-65', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 61, idadefinal: 65,   valor1vida:  46.70, valor2vidas:  37.30, valor3vidasoumais:  34.20, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S66-70', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 66, idadefinal: 70,   valor1vida:  67.50, valor2vidas:  60.70, valor3vidasoumais:  55.90, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S71-75', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 71, idadefinal: 75,   valor1vida:  91.90, valor2vidas:  82.70, valor3vidasoumais:  76.10, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S76-79', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 76, idadefinal: 79,   valor1vida: 121.70, valor2vidas: 118.10, valor3vidasoumais: 114.50, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S80-89', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 80, idadefinal: 89,   valor1vida: 185.00, valor2vidas: 181.30, valor3vidasoumais: 174.10, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S90-00', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 90, idadefinal: 1000, valor1vida: 201.70, valor2vidas: 197.70, valor3vidasoumais: 189.80, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},

                //INCLUIDO EM 13/06/2022
                // {codigo: 'R00-10', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   4.00, valor3vidasoumais:   3.80, fatorcremacao: 1.621, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R11-17', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   6.00, valor3vidasoumais:   5.40, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R18-30', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 18, idadefinal: 30,   valor1vida:  16.50, valor2vidas:  13.50, valor3vidasoumais:  11.30, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R31-40', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 31, idadefinal: 40,   valor1vida:  19.80, valor2vidas:  16.20, valor3vidasoumais:  13.40, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R41-45', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 41, idadefinal: 45,   valor1vida:  23.60, valor2vidas:  20.30, valor3vidasoumais:  16.40, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R45-50', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 46, idadefinal: 50,   valor1vida:  27.20, valor2vidas:  23.40, valor3vidasoumais:  18.90, fatorcremacao: 1.646, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R51-60', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 51, idadefinal: 60,   valor1vida:  33.10, valor2vidas:  29.10, valor3vidasoumais:  25.60, fatorcremacao: 1.647, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R61-65', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 61, idadefinal: 65,   valor1vida:  46.30, valor2vidas:  39.40, valor3vidasoumais:  33.50, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R66-70', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 66, idadefinal: 70,   valor1vida:  64.90, valor2vidas:  58.40, valor3vidasoumais:  54.30, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R71-75', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 71, idadefinal: 75,   valor1vida: 100.90, valor2vidas:  96.90, valor3vidasoumais:  93.00, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R76-79', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 76, idadefinal: 79,   valor1vida: 174.10, valor2vidas: 170.60, valor3vidasoumais: 167.20, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R80-89', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 80, idadefinal: 89,   valor1vida: 235.10, valor2vidas: 230.40, valor3vidasoumais: 225.70, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'R90-00', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 90, idadefinal: 1000, valor1vida: 246.80, valor2vidas: 241.90, valor3vidasoumais: 237.00, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99}, 
                
                // {codigo: 'S00-10', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   4.80, valor3vidasoumais:   4.60, fatorcremacao: 1.629, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S11-17', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   7.20, valor3vidasoumais:   6.40, fatorcremacao: 1.638, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S18-30', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 18, idadefinal: 30,   valor1vida:  21.30, valor2vidas:  15.90, valor3vidasoumais:  13.40, fatorcremacao: 1.646, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S31-40', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 31, idadefinal: 40,   valor1vida:  23.70, valor2vidas:  19.40, valor3vidasoumais:  16.10, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S41-45', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 41, idadefinal: 45,   valor1vida:  28.30, valor2vidas:  24.40, valor3vidasoumais:  19.70, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S46-50', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 46, idadefinal: 50,   valor1vida:  32.60, valor2vidas:  28.00, valor3vidasoumais:  22.70, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S51-60', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 51, idadefinal: 60,   valor1vida:  39.70, valor2vidas:  34.90, valor3vidasoumais:  30.70, fatorcremacao: 1.647, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S61-65', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 61, idadefinal: 65,   valor1vida:  55.60, valor2vidas:  47.30, valor3vidasoumais:  40.20, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S66-70', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 66, idadefinal: 70,   valor1vida:  77.90, valor2vidas:  70.10, valor3vidasoumais:  65.20, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S71-75', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 71, idadefinal: 75,   valor1vida: 121.10, valor2vidas: 116.30, valor3vidasoumais: 111.60, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S76-79', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 76, idadefinal: 79,   valor1vida: 208.90, valor2vidas: 204.80, valor3vidasoumais: 200.70, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S80-89', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 80, idadefinal: 89,   valor1vida: 282.10, valor2vidas: 276.40, valor3vidasoumais: 270.90, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                // {codigo: 'S90-00', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 90, idadefinal: 1000, valor1vida: 296.20, valor2vidas: 290.30, valor3vidasoumais: 284.50, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},


                //INCLUIDO EM 14/10/2023
                //{codigo: 'R00-10', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   2.00, valor3vidasoumais:   2.00, fatorcremacao: 3.300, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R11-17', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   5.40, valor3vidasoumais:   5.00, fatorcremacao: 1.833, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R18-30', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 18, idadefinal: 30,   valor1vida:  18.10, valor2vidas:  14.80, valor3vidasoumais:  10.50, fatorcremacao: 1.757, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R31-40', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 31, idadefinal: 40,   valor1vida:  19.80, valor2vidas:  16.10, valor3vidasoumais:  12.60, fatorcremacao: 1.758, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R41-45', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 41, idadefinal: 45,   valor1vida:  20.90, valor2vidas:  17.10, valor3vidasoumais:  13.70, fatorcremacao: 1.847, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R45-50', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 46, idadefinal: 50,   valor1vida:  21.00, valor2vidas:  17.50, valor3vidasoumais:  14.30, fatorcremacao: 2.057, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R51-60', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 51, idadefinal: 60,   valor1vida:  27.10, valor2vidas:  23.60, valor3vidasoumais:  20.40, fatorcremacao: 2.020, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R61-65', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 61, idadefinal: 65,   valor1vida:  34.10, valor2vidas:  28.90, valor3vidasoumais:  25.10, fatorcremacao: 1.710, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R66-70', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 66, idadefinal: 70,   valor1vida:  45.90, valor2vidas:  40.70, valor3vidasoumais:  37.00, fatorcremacao: 1.740, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R71-75', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 71, idadefinal: 75,   valor1vida:  82.20, valor2vidas:  79.70, valor3vidasoumais:  76.50, fatorcremacao: 1.439, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R76-79', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 76, idadefinal: 79,   valor1vida: 132.30, valor2vidas: 131.30, valor3vidasoumais: 127.70, fatorcremacao: 1.545, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R80-89', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 80, idadefinal: 89,   valor1vida: 178.50, valor2vidas: 178.10, valor3vidasoumais: 174.00, fatorcremacao: 1.545, clubedescontos: 3.99, clubebeneficios: 7.99},
                //{codigo: 'R90-00', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 90, idadefinal: 1000, valor1vida: 186.70, valor2vidas: 186.30, valor3vidasoumais: 182.20, fatorcremacao: 1.545, clubedescontos: 3.99, clubebeneficios: 7.99},

                //ATUALIZADO EM 26/01/24
                {codigo: 'R00-10', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   4.30, valor3vidasoumais:   4.10, fatorcremacao: 1.621, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R11-17', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   5.40, valor3vidasoumais:   4.90, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R18-30', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 18, idadefinal: 30,   valor1vida:  22.50, valor2vidas:  17.60, valor3vidasoumais:  13.10, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R31-40', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 31, idadefinal: 40,   valor1vida:  23.60, valor2vidas:  19.10, valor3vidasoumais:  15.10, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R41-45', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 41, idadefinal: 45,   valor1vida:  26.00, valor2vidas:  21.50, valor3vidasoumais:  17.50, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R45-50', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 46, idadefinal: 50,   valor1vida:  29.20, valor2vidas:  24.70, valor3vidasoumais:  20.70, fatorcremacao: 1.646, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R51-60', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 51, idadefinal: 60,   valor1vida:  36.90, valor2vidas:  32.40, valor3vidasoumais:  28.40, fatorcremacao: 1.647, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R61-65', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 61, idadefinal: 65,   valor1vida:  49.80, valor2vidas:  42.80, valor3vidasoumais:  37.80, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R66-70', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 66, idadefinal: 70,   valor1vida:  68.30, valor2vidas:  61.30, valor3vidasoumais:  56.30, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R71-75', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 71, idadefinal: 75,   valor1vida: 101.10, valor2vidas:  98.10, valor3vidasoumais:  95.10, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R76-79', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 76, idadefinal: 79,   valor1vida: 174.90, valor2vidas: 171.90, valor3vidasoumais: 168.90, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R80-89', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 80, idadefinal: 89,   valor1vida: 236.10, valor2vidas: 233.10, valor3vidasoumais: 230.10, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'R90-00', municipio:'DEFAULT', categoria:'RUBI',   idadeinicial: 90, idadefinal: 1000, valor1vida: 246.90, valor2vidas: 243.90, valor3vidasoumais: 240.90, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99}, 

                //ATUALIZADO EM 26/01/24
                {codigo: 'S00-10', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   5.10, valor3vidasoumais:   5.00, fatorcremacao: 1.629, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S11-17', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   7.70, valor3vidasoumais:   6.90, fatorcremacao: 1.638, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S18-30', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 18, idadefinal: 30,   valor1vida:  24.90, valor2vidas:  21.40, valor3vidasoumais:  16.80, fatorcremacao: 1.646, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S31-40', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 31, idadefinal: 40,   valor1vida:  27.70, valor2vidas:  24.40, valor3vidasoumais:  20.20, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S41-45', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 41, idadefinal: 45,   valor1vida:  30.50, valor2vidas:  26.20, valor3vidasoumais:  21.20, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S46-50', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 46, idadefinal: 50,   valor1vida:  33.60, valor2vidas:  28.90, valor3vidasoumais:  23.40, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S51-60', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 51, idadefinal: 60,   valor1vida:  42.50, valor2vidas:  37.40, valor3vidasoumais:  32.90, fatorcremacao: 1.647, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S61-65', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 61, idadefinal: 65,   valor1vida:  50.80, valor2vidas:  43.10, valor3vidasoumais:  36.70, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S66-70', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 66, idadefinal: 70,   valor1vida:  69.60, valor2vidas:  62.60, valor3vidasoumais:  58.30, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S71-75', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 71, idadefinal: 75,   valor1vida: 109.10, valor2vidas: 104.70, valor3vidasoumais: 100.60, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S76-79', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 76, idadefinal: 79,   valor1vida: 188.90, valor2vidas: 185.10, valor3vidasoumais: 181.40, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S80-89', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 80, idadefinal: 89,   valor1vida: 254.90, valor2vidas: 249.90, valor3vidasoumais: 244.80, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'S90-00', municipio:'DEFAULT', categoria:'SAFIRA', idadeinicial: 90, idadefinal: 1000, valor1vida: 266.50, valor2vidas: 261.20, valor3vidasoumais: 256.00, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},

                //ATUALIZADO EM 26/01/24               
                {codigo: 'O00-10', municipio:'DEFAULT', categoria:'OURO', idadeinicial:  0, idadefinal: 10,   valor1vida:   0.00, valor2vidas:   5.40, valor3vidasoumais:   5.30, fatorcremacao: 1.629, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O11-17', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 11, idadefinal: 17,   valor1vida:   0.00, valor2vidas:   8.20, valor3vidasoumais:   7.00, fatorcremacao: 1.638, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O18-30', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 18, idadefinal: 30,   valor1vida:  25.70, valor2vidas:  21.90, valor3vidasoumais:  17.00, fatorcremacao: 1.646, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O31-40', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 31, idadefinal: 40,   valor1vida:  28.20, valor2vidas:  25.00, valor3vidasoumais:  20.70, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O41-45', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 41, idadefinal: 45,   valor1vida:  31.10, valor2vidas:  28.80, valor3vidasoumais:  21.90, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O46-50', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 46, idadefinal: 50,   valor1vida:  35.00, valor2vidas:  30.20, valor3vidasoumais:  24.70, fatorcremacao: 1.648, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O51-60', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 51, idadefinal: 59,   valor1vida:  44.20, valor2vidas:  38.80, valor3vidasoumais:  34.00, fatorcremacao: 1.647, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O61-65', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 60, idadefinal: 65,   valor1vida:  59.70, valor2vidas:  48.00, valor3vidasoumais:  43.20, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O66-70', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 66, idadefinal: 70,   valor1vida:  81.80, valor2vidas:  70.00, valor3vidasoumais:  67.50, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O71-75', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 71, idadefinal: 75,   valor1vida: 113.60, valor2vidas: 110.20, valor3vidasoumais: 106.80, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O76-79', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 76, idadefinal: 79,   valor1vida: 185.70, valor2vidas: 182.60, valor3vidasoumais: 182.30, fatorcremacao: 1.649, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O80-89', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 80, idadefinal: 89,   valor1vida: 250.70, valor2vidas: 247.60, valor3vidasoumais: 245.10, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99},
                {codigo: 'O90-00', municipio:'DEFAULT', categoria:'OURO', idadeinicial: 90, idadefinal: 1000, valor1vida: 262.20, valor2vidas: 259.10, valor3vidasoumais: 257.80, fatorcremacao: 1.650, clubedescontos: 3.99, clubebeneficios: 7.99}

            ],
            servico: [
                {rubi:'Urna funerária sem visor envernizada', safira:'Urna funerária com visor envernizada, padrão especial.', ouro: "Urna funerária sem visor envernizada", descricao:'Urna funerária (de acordo com a categoria escolhida)'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM', descricao:'Local para o velório – de acordo com a lei vigente'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Ornamentação do corpo com flores naturais da estação;'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Uma coroa de flores com dizeres redigidos pela família;'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Carro funerário para remoção do corpo;'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Plantão 24 horas para atendimento telefônico'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Despachante autorizado, apto para tomar, em nome da família, todas as providências necessárias para o funeral'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Remoção do corpo para o cemitério onde será realizado o funeral;'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Locação de espaço para sepultamento (gaveta ou carneiro) - caso o titular e/ou beneficiário(s) não disponha(m) de jazigo, poderá(ão) ocupar temporariamente em cemitério público do tipo tradicional indicado pela família. Se o cemitério escolhido não dispuser naquele momento de espaço tipo gaveta ou carneiro, a família escolherá outro cemitério que disponha do espaço tipo gaveta ou carneiro ou a realização do sepultamento naquele escolhido, mesmo que o sepultamento se dê em padrão inferior tipo “cova rasa” - chão. O período de locação do espaço para sepultamento previsto neste contrato se limitará ao prazo de 03 (três) anos, após o qual será de inteira responsabilidade da família'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Pagamento das taxas municipais e cemiteriais relativas ao funeral;'},
                {rubi:'SIM', safira:'SIM', ouro: 'SIM',  descricao:'Coche (transporte interno)'},
                {rubi:'Opcional - CONSULTE', safira:'Opcional - CONSULTE',  ouro: 'Opcional - CONSULTE', descricao:'Cremação'},
                {rubi:'NÃO', safira:'NÃO',  ouro: 'SIM', descricao:'Tarifa Exumação'},
            ]

        };

        this.valores = {
            
            
               
                        
        }


    }
    
    getCarsSmall() {
        return axios.get('assets/demo/data/cars-small.json')
                .then(res => res.data.data);
    }

    getCarsMedium() {
        return axios.get('assets/demo/data/cars-medium.json')
                .then(res => res.data.data);
    }

    getCarsLarge() {
        return axios.get('assets/demo/data/cars-large.json')
                .then(res => res.data.data);
    }

    // getQRCode() {
    //     return (<QRCode
    //         id="qrProposta"
    //         value={this.proposta.pr}
    //         // size={290}
    //         level={"H"}
    //         includeMargin={true}
    //     />);
    // }
}