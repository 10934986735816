import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return  (
            <div className="layout-footer">
                <span className="footer-text" style={{'marginRight': '5px'}}>ABF Propostas V.2024.01</span>
                <img src="assets/layout/images/logo-libotti-gray.png" alt="libotti" width="50"/> 
                {/* <span className="footer-text" style={{'marginLeft': '5px'}}>Libotti</span> */}
            </div>
        );
    }
}