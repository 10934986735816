import React from 'react';
import { QRCode } from 'react-qrcode-logo';

const styles = {
    root:{
        //width: '100%',
        //backgroundColor: '#fff',
        borderRadius: '10px',
        // display: 'flex',  
        justifyContent:'center', 
        alignItems:'center', 
        padding: '1vw'
    },
    qr:{
        padding: 1,
    },
    texto:{
        color: '#cecece',
    }
}

const QRProposta = ({ id }) => {
    const uri = 'https://proposta.abfassistencia.com.br/' + id;
    // console.log('URI', uri)
    return (
        <div style={styles.root}>
            <div >
                <QRCode 
                    id="qrProposta" 
                    value={uri}
                    size={110}
                    level={"M"}
                    includeMargin={false}
                    style={styles.qr}
                />
            </div>
            <span style={styles.texto}>abrir no celular</span>
        </div>
    )
  }
  
  export default QRProposta