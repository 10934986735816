import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD-6sQgmje04ac8zImXNJoihduYv4LWTis",
    authDomain: "abf-propostas.firebaseapp.com",
    databaseURL: "https://abf-propostas.firebaseio.com",
    projectId: "abf-propostas",
    storageBucket: "abf-propostas.appspot.com",
    messagingSenderId: "1015997043640",
    appID: "1:1015997043640:web:a9e5bf93e8ff75effbf54d",
    measurementId: "G-LK2XNDNX8T"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase


//   firebase.auth().signInAnonymously().catch(function(error) {
//     // Handle Errors here.
//     var errorCode = error.code;
//     var errorMessage = error.message;
//     // ...
//     console.log('error login:', error)
//   });

//   firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       console.log('logou...');
//       // User is signed in.
//       var isAnonymous = user.isAnonymous;
//       var uid = user.uid;
//       user.getIdTokenResult().then(x => {
//           localStorage.setItem('abftoken', x.token);
//           console.log('token:', x.token);
//         })
//     } else {
//         console.log('nao logou...');
//         localStorage.setItem('abftoken', null);
//     }

//   });